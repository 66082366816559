import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../newscontainer/news.css'

const Newsconponent = (props) => {
    console.log(props)
    const navigate = useNavigate();


    function postdetailsNavigate() {
        localStorage.setItem('postdetailsid', `${props.id}`);

    

        // navigate(`/${localStorage.getItem('categoryname')}/${localStorage.getItem('subcategoryname')}/${props.title.replaceAll(' ', '_')}`);
        navigate(`/${props.title.replaceAll(' ', '_')}`);

    }
    return (
        <div className='borderradious curserpointer ' onClick={postdetailsNavigate} >
            <div className='d-flex   justify-content-between m-1 gap-x-1 '>
                <div className='col-md-7 col-7 col-sm-7 col-lg-7    ml-1 leftalignsaprate textonhover' style={{ paddingLeft: '5px' }}>
                    <h5 className=' textbold3 lineheight3  '>{props.title}</h5>
                    <div className='mt-4   '>
                        <h6 className='textbold2 textcolorprimary lineheight '>{props.type}</h6>
                        <h6 className='textnormal'> {props.Published} </h6>
                    </div>
                </div>
                {/* <div className=' col-md-1 col-1 col-sm-1 col-lg-1    ml-1  textonhover'></div> */}
                <div className=' '  >
                    <img className='h-100' style={{objectFit:"cover"}} src={props.news} alt="news" />
                {/* <div className=' p-0 m-0 col-md-4  col-4 col-sm-4 col-lg-4  mt-2 responsiveimgcontainer'  >
                    <img className='h-75' width={100} src={props.news} alt="news" /> */}
                </div>
                {/* <img className=' p-0 m-0 col-md-4  col-4 col-sm-4 col-lg-4 responsiveimgcontainer' width={100} src={props.news} alt="news" /> */}

            </div>
        </div>
    );
}

export default Newsconponent;
