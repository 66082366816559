import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../component/states.css';
function Statecontainer(props) {
    const navigate = useNavigate();

    function postdetailsNavigate() {
        localStorage.setItem('subcategoryid', props.id);
        navigate(`/State/${props.hindiname}`);
    }
    return (
        <>
            <div className='col p-1  text-center containerstates ' onClick={postdetailsNavigate}>
                {/* <h5 className='textbold2'>{props.name}</h5> */}
                <div className='my-1'>
                    <img src={props.images}  height={80} width={80} className="image_background" alt="" />
                </div>
                <h5 className='textbold2'>{props.hindiname}</h5>
            </div>

        </>
    );
}

export default Statecontainer;
