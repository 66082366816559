import { useEffect } from 'react';
// const Main = React.lazy(() => import('./pages/Home/indexMain'));
import { useState } from 'react';
import '../component/stories.css';
import { Helmet } from 'react-helmet';
// import pics from '../../../../assets/images/web1.png';
import pics1 from '../../../../assets/images/web2.png';
// import Loader from '../../../../helper/loader';
import { defaultscript } from '../../../../helper/simpler_state';
import { Webstorydatas } from '../../../../request/Post';
import { Carousel } from 'react-bootstrap';
import { Shimmer } from 'react-shimmer';
import { RWebShare } from 'react-web-share';
import { FiSend } from 'react-icons/fi';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from 'react-router-dom';
function Webstories(props) {
    const [storylist, setStorylist] = useState()
    let location = useLocation();
    // const [Loaders, setLoaders] = useState(true)
    useEffect(() => {
        // setLoaders(true);
        Webstorydatas(localStorage.getItem('storyid')).then((users) => {

            console.log(users);
            setStorylist(users)
        }).catch((value) => {
            // console.log(value);
        });
        // setLoaders(false);

    }, [])
    const baseurl = 'https://sarkariprepweb.proapp.in';
    console.log(`${baseurl}${location.pathname}`, 'location');
    var scripts = storylist !== undefined ? storylist.data.seoScripts ? storylist.data.seoScripts.length > 0 ? storylist.data.seoScripts.map((value) => { return value }) :

        defaultscript
        :
        defaultscript
        :
        defaultscript
    return (
        // <AmpStory
        //     title="Example title"
        //     publisher="Example publisher"
        //     publisher-logo-src={pics1}
        //     poster-portrait-src={pics}
        // >
        // {storylist === undefined
        //     ?
        //     <>
        //         <Loader />
        //     </> :
        //         // <Loader />
        //         storylist.data.stories && storylist.data.stories.length < 0 ? storylist.data.stories.map((index, id) => {
        //             console.log("index")
        //             console.log(index)
        //             return (
        // <AmpStoryPage id="first">
        //     <AmpStoryGridLayer template="fill">
        //         <AmpImg key={index._id} src={pics1} height="720" width="1280" layout="responsive" />
        //     </AmpStoryGridLayer>
        //     <AmpStoryGridLayer template="vertical"  >
        //         <AmpHeader level={9} title={storylist.data.title} />
        //         <AmpHeader />
        //         <AmpParagraph />
        //         <AmpParagraph text="Subtitle" />
        //     </AmpStoryGridLayer >
        // </AmpStoryPage >
        //             )
        //         })
        //             : <Loader />
        //     }
        // </AmpStory >
        <div className=''>
            <Helmet>
                <script type="application/ld+json" >
                    {JSON.stringify(scripts)}
                </script>
                <title>
                    {storylist !== undefined ? storylist.data ? storylist.data.webStory ? storylist?.data?.webStory?.seo ? storylist?.data?.webStory?.seo?.title : '' : '' : '' : ''}</title>
                <meta charSet="utf-8" />
                <meta name="description" content={
                    storylist?.data?.webStory?.seo?.description} />
                <meta name="keywords" content={
                    storylist?.data?.webStory?.seo?.keywords} />
                <link rel="canonical" title={storylist?.data?.webStory?.seo?.title} href={storylist?.data?.webStory?.seo?.url} />
            </Helmet>
            {

                <Carousel variant="dark" className='' >
                    {
                        storylist === undefined
                            ? <>
                                <div class="bg-image-shimmer " style={{ 'height': '125vh', 'backgroundImage': `url(${pics1})` }}>
                                </div>

                                <div className='image2' style={{ textAlign: '-webkit-center' }}>
                                    <Shimmer className='h-100 w-100' />
                                </div> </> :

                            storylist.data.webStory.stories && storylist.data.webStory.stories.length > 0 ?
                                storylist.data.webStory.stories.map((value, index) => {
                                    // console.log(value);
                                    AOS.init();
                                    return (

                                        <Carousel.Item

                                            interval={4000} style={{ textAlign: '-webkit-center' }}  >
                                            {/* <div className='bg-image' style={{  }}></div> */}

                                            <div class="bg-image  " style={{ 'height': '125vh', 'backgroundImage': `url(${value.file})` }}>
                                            </div>

                                            <img id='#img-hover-zoom'
                                                className="w-md-25 img-hover-zoom image2"
                                                src={value.file}
                                                alt={value.title}
                                            />
                                            <div >
                                                <RWebShare
                                                    data={{
                                                        text: `${storylist.data.webStory.name}`,
                                                        url: `${baseurl}${location.pathname}`,
                                                        title: `${value.title}`,
                                                    }}
                                                    onClick={() => { }}
                                                >
                                                    <FiSend className='imagesharebutton' size={20} />
                                                </RWebShare>
                                            </div>


                                            <Carousel.Caption>
                                                <div data-aos="fade-down"
                                                    // data-aos-easing="linear"
                                                    data-aos-duration="1500" className='textposition p-1'>
                                                    <div className='backgroundposition '>
                                                        <div style={{ 'color': 'white', 'fontWeight': '500', 'fontSize': '20px !important', 'lineHeight': '25px' }} className=''>{value.title}</div>
                                                    </div>
                                                    <div className='backgroundposition1'>
                                                        <p style={{ 'color': 'white' }}>{value.description}</p>
                                                    </div>
                                                </div>
                                                {/* <div className='bg-success textposition p-1'></div> */}
                                            </Carousel.Caption>
                                        </Carousel.Item>

                                    )
                                })
                                :
                                <>
                                    <div class="bg-image-shimmer " style={{ 'height': '125vh', 'backgroundImage': `url(${pics1})` }}>
                                    </div>

                                    <div className='image2' style={{ textAlign: '-webkit-center' }}>
                                        <Shimmer className='h-100 w-100' />
                                    </div> </>
                    }
                </Carousel>
            }

        </div>

    );
}

export default Webstories;
