import React, { useEffect, useState } from 'react';
import { Markup } from 'interweave';
import Layout from '../../../layout';
import { Helmet } from 'react-helmet';
import { goToTop } from '../../../helper/simpler_state';
import { cms } from '../../../request/Post';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { AiFillHome } from 'react-icons/ai';
import Postdetailsheader from '../postdetails/component/postdetailsheader';
import { Shimmer } from 'react-shimmer';
const CustomPage = () => {
    let userId = useParams();
    const navigate = useNavigate();
    const [isLoder, setLoaders] = useState(true);
    const [postddetailsdata, setpostdetailsdata] = useState(false);
    useEffect(() => {
        goToTop();
        setLoaders(true);

        cms(localStorage.getItem('cms')).then((users) => {
            setpostdetailsdata(users)


            console.log(users);
        });
        setLoaders(false);
    }, [userId.name]);
    var postdatacontent = postddetailsdata?.data?.content;
    return (<>


        <Layout>
            <></>
            <></>
            <></>
            <div className='container'>
                <div className='row  postdetailsecontainer justify-content-center '>
                    <div className='col-sm-12 col-md-10'>
                        <div className='my-2 row'>
                            <Breadcrumb className='justify-content-start breadcrumb-wrapper'>
                                <Breadcrumb.Item ><AiFillHome onClick={() => navigate("/")} /> </Breadcrumb.Item>
                                <Breadcrumb.Item >
                                    {userId.name}
                                </Breadcrumb.Item>

                            </Breadcrumb>

                            {postddetailsdata === false
                                ?
                                <>
                                    <Shimmer width={700} height={800} />
                                </> : <>


                                    <Postdetailsheader postdetails={postddetailsdata.data.name} />
                                    <div className=' textnormal texttablebody'>
                                        {/* {doc.firstChild.innerHTML} */}
                                        <Markup content={postdatacontent} />
                                    </div>


                                </>



                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </>

    );
}

export default CustomPage;