import React, { useEffect, useState } from 'react';

import Headtype1 from '../../components/headtype1';
import Rightbar from '../../components/rightbar/rightbar';
// import imgnews from '../../../assets/images/gk.png'
// import imgdrdo from '../../../assets/images/drdo.png'
// import newss from '../../../assets/images/news.png'
// import fci from '../../../assets/images/fci.png'
import Newsconponent from '../../components/newscontainer/newsconponent';
import Adrectengal from '../../components/adds/Adrectengal';
import { MetaTags } from 'react-meta-tags';

import { posts_state_request } from '../../../request/Post';
import { useNavigate, useParams } from 'react-router-dom';
import Jobsui from '../../components/jobscontainers/jobcontainer/jobsui';
import { defaultscript, goToTop } from '../../../helper/simpler_state';
import Layout from '../../../layout';
import { Helmet } from 'react-helmet';
import JobsuiShimmer from '../../../helper/shimmer/jobsuishimmer';
import { Breadcrumb } from 'react-bootstrap';
import { AiFillHome } from 'react-icons/ai';
const PostofState = () => {
    const navigate = useNavigate();
    let param = useParams();
    const [page, setpage] = useState(1);
    const [subcategoriespost, setsubcategoriespost] = useState(false);
    const [postdata, setpostdata] = useState([]);

    const [pagination, setPagination] = useState(1);
    const [scrollCalled, setscrollCalled] = useState(false);
    const [totalpage, setTotalpage] = useState(2);
    const [demopage, setdemopage] = useState([]);
    useEffect(() => {
        goToTop();
    }, []);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);
    useEffect(() => {
        posts_state_request(localStorage.getItem('subcategoryid'), pagination).then((users) => {
            setsubcategoriespost(users)
            // console.log(users.data.dataRecords.totalPages);
            setdemopage([...demopage, ...users.data.data])
            setTotalpage(users.data.dataRecords.totalPages)
        });
    }, [pagination]);

    const handleScroll = async () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight - 120) {
            setpage(prev => prev + 1);
            console.warn(totalpage, 'totalpage');
            if (pagination <= totalpage) {
                setscrollCalled(true);
                setPagination(pagination + 1);
                //    await fetchdata(pagination);
            }
            // posts_request(localStorage.getItem('categoryid')).then((users) => setpostdata(users));
        }
    }


    var scripts = subcategoriespost !== false ?
        subcategoriespost?.data?.seo
            ?

            subcategoriespost?.data?.seo?.scripts
                ? Object.keys(subcategoriespost?.data?.seo?.scripts).map((value, id) => { return (id !== undefined ? subcategoriespost?.data?.seo?.scripts[value] : defaultscript) })
                :
                defaultscript
            :
            defaultscript
        :
        defaultscript;
    return (
        <Layout>
            <Helmet>
                <script type="application/ld+json" >

                    {JSON.stringify(scripts)}



                </script>
                <title>{subcategoriespost !== false ? subcategoriespost.data ? subcategoriespost.data.seo ? subcategoriespost?.data?.seo?.name : '' : '' : ''}
                </title>
                <meta charSet="utf-8" />
                <meta name="description" content={
                    subcategoriespost?.data?.seo?.description} />
                <meta name="keywords" content={
                    subcategoriespost?.data?.seo?.keywords} />
                <link rel="url" href={subcategoriespost?.data?.seo?.url} />

                <meta property="og:title" content={postdata?.data?.seo?.name} />
                {/* homedata.data.scripts.map((value, index) =>value)  */}
                {/* <title>Sarkariprep:{param.name.replaceAll('_', ' ')}</title> */}
            </Helmet>
            <MetaTags>
                {/* <meta name="description" content="history ,gk,government knowledge" />
                <meta name="keywords" content="gk,government knowledge,History GK" />
                <meta name="keywords" content="history,gk,government knowledge,History GK " />
                <meta property="og:title" content="Sarkariprep :History GK,government knowledge,government knowledge" /> */}
            </MetaTags>


            <div className='row  justify-content-center'>

                <div className='col-sm-12 col-md-8'>

                    <div className='container'>
                        <Breadcrumb className='justify-content-start breadcrumb-wrapper'>
                            <Breadcrumb.Item className='fontsizeBreadcrumb'><AiFillHome onClick={() => navigate("/")} /> </Breadcrumb.Item>
                            <Breadcrumb.Item className='fontsizeBreadcrumb' onClick={() => navigate(-1)} > {param.name}</Breadcrumb.Item>
                        </Breadcrumb>
                        <Headtype1 head={param.name.replaceAll('_', ' ')} />
                        <div className='section my-2 row   '>
                            <div className=' col-md-8 '>

                                {
                                    subcategoriespost !== false ?
                                        demopage ?

                                            demopage.map((index, id) => {
                                                const update = index.updatedAt.split("T")
                                                if (id === 0) {
                                                    id = 1
                                                }
                                                const addsid = id % 4
                                                return (<>

                                                    <div key={index._id} >
                                                        {index.type === 'Job' ?
                                                            <Jobsui id={index._id} head={index ? index?.description ? index?.description?.short_heading : '' : ''} qualification={index.description ? index.description.qualifications : ''} date={index.description ? index.description.last_date : ''} no={index.description ? index.description.no_of_posts : ''} title={index ? index?.description ? index.description.post_name : '' : ''} />
                                                            :
                                                            <Newsconponent id={index._id} className='leftalignsaprate' news={index.image} title={index.heading} type='न्यूज़' Published={`Published on - ${update[0]}`} />}
                                                    </div>
                                                    {addsid === 3 ? <><Adrectengal /></> : <></>}
                                                </>

                                                )
                                            }
                                            )
                                            : <>
                                                <JobsuiShimmer category={false} pwidth={625} /></>
                                        : <><JobsuiShimmer category={false} pwidth={625} /></>

                                }
                            </div>

                            <div className='d-none d-md-block d-lg-block col-md-4 col-12 col-sm-12'>
                                <Rightbar />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default PostofState;
