import React from 'react';
import '../adds/add.css'
const Adrectengal = () => {
    return (
        <div className='my-2'  >
            <p className='Adrectengal'>Advertisement</p>
        </div>
    );
}

export default Adrectengal;
