import React from 'react';

const Postdetailsheader = (props) => {
    return (
        <>
            <h1 className='textbold2 fontsize1' style={{ 'textAlignLast': 'left' ,textAlign:"-webkit-left"}}>
                {props.postdetails}
            </h1>
        </>
    );
}

export default Postdetailsheader;
