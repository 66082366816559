import React, { useEffect, useState } from 'react';
// import ButtomFooter from '../../components/Footer/buttom_footer';
// import Header from '../../components/header/header';
// import pics from '../../../assets/images/web1.png';
// import pics1 from '../../../assets/images/web2.png';
// import pics2 from '../../../assets/images/sach.png';
import Headtype1 from '../../components/headtype1';
import './component/stories.css';
// import { useNavigate } from 'react-router-dom';
// import { MetaTags } from 'react-meta-tags';
import { Webstories } from '../../../request/Post';
// import Loader from '../../../helper/loader';
import Layout from '../../../layout';
import Tableshimmer from '../../../helper/shimmer/tableshimmer';
// import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { defaultscript } from '../../../helper/simpler_state';
// import { useNavigate } from 'react-router-doma';
// import { AiFillHome } from 'react-icons/ai';
// import { Breadcrumb } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
const Storiespage = () => {
    const [storylist, setStorylist] = useState()
    const [Loaders, setLoaders] = useState(true)
    useEffect(() => {
        setLoaders(true);
        Webstories().then((users) => setStorylist(users));
        setLoaders(false);
    }, [])
    // const navigate = useNavigate();
    
        var scripts = storylist !== undefined ?
        storylist?.data?.seo
            ?

            storylist?.data?.seo?.scripts
                ? Object.keys(storylist?.data?.seo?.scripts).map((value, id) => { return (id !== undefined ? storylist?.data?.seo?.scripts[value] : defaultscript) })
                :
                defaultscript
            :
            defaultscript
        :
        defaultscript
    return (
        <Layout>
            {
                Loaders ?
                    <div>
                    </div> :
                    <div className="h-100" style={{ height: '100px' }}>
                        <Helmet>
                            <script type="application/ld+json" >

                                {JSON.stringify(scripts)}
                            </script>
                            <title>
                                {storylist !== undefined ? storylist.data ? storylist.data.seo ? storylist?.data?.seo?.name : '' : '' : ''}</title>
                            <meta charSet="utf-8" />
                            <meta name="description" content={
                                storylist?.data?.seo?.description} />

                            <meta name="keywords" content={
                                storylist?.data?.seo?.keywords} />
                            <link rel="canonical" href={storylist?.data?.seo?.url} />


                        </Helmet>
                        {/* <MetaTags>
                            <title>Sarkariprep:Web Stories 2022,Stories India,Stories</title>

                            <meta name="description" content="Web Stories 2022, Web Stories, सरकारी योजना, सरकारी योजना हिंदी, Central Government Schemes, State Government Web Stories, Web Stories List 2022, Web Stories List Pdf, All India Web Stories, Government Schemes Hindi, Web Stories List of All States, Latest Government Schemes Hindi, State Wiseyojana Portal, सरकारी योजना रजिस्ट्रेशन, Web Stories Online Form, State Wiseprep" />
                            <meta name="keywords" content="Web Stories, Web Stories India,  Nokri News India, State Wiseprep Nokri" />
                            <meta name="keywords" content="Web Stories, Web Stories, Web Stories India,Web Stories News India, State Wise naukri result, State Wise job, State Wise result, State Wise exam preparation, government jobs, State Wise jobs portal, rojgar result, State Wise naukri, online test series, State Wise pariksha, govt exams preparation, State Wise exam, State Wise job find, rojgar samachar portal, employment news, govt jobs, State Wise exam result, rojgaralert, State Wise bharti, State Wise prep, job alert, govt job alert, State Wise job, State Wise job alert, State Wiseprep job portal, today news headlines, hindi news headlines, job news, education news, State Wise job news headlines, rojgar samachar news, employment news, State Wise result news, freejobalert news" />
                            <meta property="og:title" content="Web Stories : State Wise News,Web Stories, Web Stories India,Web Stories News India, Web Stories Yojana  2022" />

                        </MetaTags> */}

                        <div className='container_flex'>

                            <div className='row  justify-content-center'>

                                <div className='col-sm-11 col-md-10 col-lg-9'>
                                    {/* <Breadcrumb className='justify-content-start breadcrumb-wrapper'>
                                <Breadcrumb.Item ><AiFillHome onClick={() => navigate("/")} /> </Breadcrumb.Item>
                                <Breadcrumb.Item    >
                                    Web stories
                                </Breadcrumb.Item>

                            </Breadcrumb> */}
                                    <Headtype1 head='वेब स्टोरी' />
                                    <div className='row gx-2 gy-3 my-2'  >

                                        {storylist !== undefined ?
                                            storylist.data.data.map((index, id) => {
                                                return (<div key={index._id} className='  col-sm-12  col-md-6  col-lg-3  text-center ' onClick
                                                    ={async () => {
                                                        await localStorage.setItem('storyid', index._id)
                                                        //    await  console.log(index.name.replaceAll(' ', '-'));
                                                        await window.open(`/webstories/${index.name.replaceAll(' ', '-')}`, "_blank")
                                                        // navigate(`/webstories/${index.name}`)
                                                    }}
                                                >
                                                    <div className='storiycontainer' >
                                                        <div className='p-1 bg-light  ' style={{ 'overflow': 'hidden' }}  >
                                                            <img className='zoom-in-out-box img-hover-zoomstory' style={{objectFit: "none"}} height={200} src={index.thumbnail} alt={index.title} title={index.title} />
                                                        </div>
                                                        <hr className='my-0' />
                                                        <div className='textbold2 dotstory dotstory1 my-1'>{index.name}</div>
                                                    </div>
                                                </div>)
                                            }) : <> <Tableshimmer />  </>
                                        }
                                        {/* <div className='  col-sm-4 col-6 col-md-4  col-lg-3  text-center ' onClick
                                    ={() => window.open("/webstories", "_blank")}

                                >
                                    <div className='storiycontainer'>

                                        <div className=' '>

                                            <img className='imgdecoration ' src={pics1} alt=" " title=" " />
                                        </div>
                                        <div className='textbold2 my-2'>जस्सी बन मोना सिंह को मिली थी पहचान</div>
                                    </div>
                                </div>

                                <div className='  col-sm-4 col-6 col-md-4  col-lg-3 text-center ' onClick
                                    ={() => window.open("/webstories", "_blank")} >
                                    <div className='storiycontainer'>

                                        <div className=' '>

                                            <img className='imgdecoration' src={pics} alt=" " title=" " />
                                        </div>
                                        <div className='textbold2 my-2'>शरारा पहन कटरीना कैफ ने दिया पोज</div>
                                    </div>
                                </div>
                                <div className='  col-sm-4 col-6 col-md-4  col-lg-3  text-center ' onClick
                                    ={() => window.open("/webstories", "_blank")} >
                                    <div className='storiycontainer'>

                                        <div className=' '>

                                            <img className='imgdecoration' src={pics1} alt=" " title=" " />
                                        </div>
                                        <div className='textbold2 my-2'>जस्सी बन मोना सिंह को मिली थी पहचान</div>
                                    </div>
                                </div>

                                <div className='  col-sm-4 col-6 col-md-4  col-lg-3  text-center ' onClick
                                    ={() => window.open("/webstories", "_blank")} >
                                    <div className='storiycontainer'>

                                        <div className=' '>

                                            <img className='imgdecoration' src={pics} alt=" " title=" " />
                                        </div>
                                        <div className='textbold2 my-2'>शरारा पहन कटरीना कैफ ने दिया पोज</div>
                                    </div>
                                </div>
                                <div className='  col-sm-4 col-6 col-md-4  col-lg-3  text-center ' onClick
                                    ={() => window.open("/webstories", "_blank")} >
                                    <div className='storiycontainer'>

                                        <div className=' '>

                                            <img className='imgdecoration' src={pics1} alt=" " title=" " />
                                        </div>
                                        <div className='textbold2 my-2'>जस्सी बन मोना सिंह को मिली थी पहचान</div>
                                    </div>
                                </div>
                               
                                  */}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>}

        </Layout>
    );
}

export default Storiespage;
