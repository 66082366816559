import React from 'react';
// import '../../view/components/jobscontainers/jobcontainer/jobsui.css'
// import { IoBriefcase } from 'react-icons/io5';
// import { FaCalendarAlt } from 'react-icons/fa';
// import { AiFillStar } from 'react-icons/ai';
// import { HiUser } from 'react-icons/hi';
// import { useNavigate } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import { Col, Row } from 'react-bootstrap';
import Head from '../../view/components/headtype';
import { useEffect } from 'react';
const JobsuiShimmer = (props) => {
    var shimmerwidth = props.pwidth;


    console.log(window.innerHeight);
    // const navigate = useNavigate();
    // console.log(props);
    // console.log(window.innerWidth, 'window.innerHeight');
    // console.log(window.innerWidth, 'window.innerHeight');
    return (<>
        {props.category ? <>
            <Row className='mb-1 mx-1 '>

                <Col className='  mx-1 ml-3  containerstates curserpointer textonhover p-1' >
                    <h1 className='textbold2'><Shimmer height={10} width={80} /> </h1>
                    <div className='  row justify-content-center my-3 ' >
                        <div className='col-auto image_background '   >
                            <img src={props.images} className="p-1 h-100 " alt={props.name} />
                        </div>

                    </div>
                    <h1 className='textbold2 mb-0'><Shimmer height={10} width={80} /> </h1>
                </Col>
                <Col className='  mx-1   containerstates curserpointer textonhover p-1' >
                    <h1 className='textbold2'><Shimmer height={10} width={80} /> </h1>
                    <div className='  row justify-content-center my-3 ' >
                        <div className='col-auto image_background '   >
                            <img src={props.images} className="p-1 h-100 " alt={props.name} />
                        </div>

                    </div>
                    <h1 className='textbold2 mb-0'><Shimmer height={10} width={80} /> </h1>
                </Col>
                <Col className='  mx-1   containerstates curserpointer textonhover p-1' >
                    <h1 className='textbold2'><Shimmer height={10} width={80} /> </h1>
                    <div className='  row justify-content-center my-3 ' >
                        <div className='col-auto image_background '   >
                            <img src={props.images} className="p-1 h-100 " alt={props.name} />
                        </div>

                    </div>
                    <h1 className='textbold2 mb-0'><Shimmer height={10} width={80} /> </h1>
                </Col>
                <Col className='  mx-1   containerstates curserpointer textonhover p-1' >
                    <h1 className='textbold2'><Shimmer height={10} width={80} /> </h1>
                    <div className='  row justify-content-center my-3 ' >
                        <div className='col-auto image_background '   >
                            <img src={props.images} className="p-1 h-100 " alt={props.name} />
                        </div>

                    </div>
                    <h1 className='textbold2 mb-0'><Shimmer height={10} width={80} /> </h1>
                </Col>
            </Row>
            <Head head={<Shimmer height={30} width={100} />} />
        </> : <></>}

        <div className='borderradious p-0' >
            <div className='text-center m-1    '  >
                <Shimmer height={30} width={props.pwidth} />
                {/* <h1 className='textbold2 textcolorprimary lineheight'  > {props.head}</h1> */}
            </div>
            <div className='borderradioustop'>
                <Shimmer height={130} width={props.pwidth} className="m-1" />
            </div>

        </div>
        <div className='borderradious p-0' >
            <div className='text-center m-1    '  >
                <Shimmer height={30} width={props.pwidth} />
                {/* <h1 className='textbold2 textcolorprimary lineheight'  > {props.head}</h1> */}
            </div>
            <div className='borderradioustop'>
                <Shimmer height={130} width={props.pwidth} className="m-1" />
            </div>

        </div> <div className='borderradious p-0' >
            <div className='text-center m-1    '  >
                <Shimmer height={30} width={props.pwidth} />
                {/* <h1 className='textbold2 textcolorprimary lineheight'  > {props.head}</h1> */}
            </div>
            <div className='borderradioustop'>
                <Shimmer height={130} width={props.pwidth} className="m-1" />
            </div>

        </div>
        <div className='borderradious p-0' >
            <div className='text-center m-1    '  >
                <Shimmer height={30} width={props.pwidth} />
                {/* <h1 className='textbold2 textcolorprimary lineheight'  > {props.head}</h1> */}
            </div>
            <div className='borderradioustop'>
                <Shimmer height={130} width={props.pwidth} className="m-1" />
            </div>

        </div>
        <div className='borderradious p-0' >
            <div className='text-center m-1    '  >
                <Shimmer height={30} width={props.pwidth} />
                {/* <h1 className='textbold2 textcolorprimary lineheight'  > {props.head}</h1> */}
            </div>
            <div className='borderradioustop'>
                <Shimmer height={130} width={props.pwidth} className="m-1" />
            </div>

        </div>
    </>
    );
}

export default JobsuiShimmer;
