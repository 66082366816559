import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Shimmer } from 'react-shimmer';
import { Linearshimmer } from './linearshimmer';
import Tablesrhimmer from './tablesrhimmer';
import '../../view/screen/Home/Compnent/homepage_wraper.css'
// import Shimmer from 'react-js-loading-shimmer';
const ShimmerSmall = () => {
    console.log(window.innerWidth);


    return (
        <div className=" ">
            <div className="content-grid">


                {/* 
                    <div className="text1">
                        <div className={"row"}>
                            <div className={"col-sm-4"}><Shimmer height={"50px"} /></div>
                            <div className={"col-sm-8"}><Shimmer height={"25px"} /><Shimmer
                                height={"25px"} /></div>
                        </div>
                    </div> */}
                {/* <table className="table">
                    <tbody>
                        <tr>
                            <td className="align-middle">
                                <Tablesrhimmer pwidth={302} /> <br /><Tablesrhimmer pwidth={302} /> </td>
                            <td className="align-middle">
                                <Tablesrhimmer pwidth={302} /> <br /><Tablesrhimmer pwidth={302} /></td>
                            <td className="align-middle">
                                <Tablesrhimmer pwidth={302} /> <br /><Tablesrhimmer pwidth={302} /></td>
                        </tr>
                         
                    </tbody>
                </table> */}
                <div className='row'> 
                <div className='    col-sm-12 col-md-6  col-lg-4 col-xl-4' >
                    <table className="table table-bordered  ">
                        <thead>
                            <tr>
                                <th scope="col ">  <h1 className=' textbold2 dottable'><Shimmer height={20} width={250} /> </h1></th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr>
                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>





                        </tbody>
                    </table>
                </div>
                 
                <div className='    col-sm-12 col-md-6  col-lg-4 col-xl-4' >
                    <table className="table table-bordered  ">
                        <thead>
                            <tr>
                                <th scope="col ">  <h1 className=' textbold2 dottable'><Shimmer height={20} width={250} /> </h1></th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr>
                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>





                        </tbody>
                    </table>
                </div>
                 
                <div className='    col-sm-12 col-md-6  col-lg-4 col-xl-4' >
                    <table className="table table-bordered  ">
                        <thead>
                            <tr>
                                <th scope="col ">  <h1 className=' textbold2 dottable'><Shimmer height={20} width={250} /> </h1></th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr>
                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>





                        </tbody>
                    </table>
                </div>
                 
                <div className='    col-sm-12 col-md-6  col-lg-4 col-xl-4' >
                    <table className="table table-bordered  ">
                        <thead>
                            <tr>
                                <th scope="col ">  <h1 className=' textbold2 dottable'><Shimmer height={20} width={250} /> </h1></th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr>
                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>





                        </tbody>
                    </table>
                </div>
                 
                <div className='    col-sm-12 col-md-6  col-lg-4 col-xl-4' >
                    <table className="table table-bordered  ">
                        <thead>
                            <tr>
                                <th scope="col ">  <h1 className=' textbold2 dottable'><Shimmer height={20} width={250} /> </h1></th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr>
                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>





                        </tbody>
                    </table>
                </div>
                 
                <div className='    col-sm-12 col-md-6  col-lg-4 col-xl-4' >
                    <table className="table table-bordered  ">
                        <thead>
                            <tr>
                                <th scope="col ">  <h1 className=' textbold2 dottable'><Shimmer height={20} width={250} /> </h1></th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr>
                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>
                            <tr

                            >

                                <td className='d-flex flex-row    m-0' >
                                    <div className='green d-flex   '></div>
                                    <h6 className=' textonhover textnormal1'>

                                        <Shimmer height={20} width={250} />

                                    </h6>

                                </td>

                            </tr>





                        </tbody>
                    </table>
                </div>
                 
                
                </div>
                {/* <Row className='g-5'>

                    <Col sm={6} lg={4} md={4}>
                        <div className="align-middle">
                            <Tablesrhimmer pwidth={302} />
                            <br />
                            <Tablesrhimmer pwidth={302} />
                        </div>
                    </Col>
                    <Col sm={6} lg={4} md={4}>
                        <div className="align-middle">
                            <Tablesrhimmer pwidth={302} /> <br />
                            <Tablesrhimmer pwidth={302} /> </div>
                    </Col>
                    <Col sm={6} lg={4} md={4}>
                        <div className="align-middle">
                            <Tablesrhimmer pwidth={302} /> <br />
                            <Tablesrhimmer pwidth={302} />
                        </div>
                    </Col>
                </Row> */}
            </div>
            {/* </div> */}
        </div>

    );
}

export default ShimmerSmall;

