import React from 'react';
import slider from "../../../../assets/images/sliderimage.png";
import right from "../../../../assets/images/right.png";
import '../Compnent/home.css';
import { useNavigate } from 'react-router-dom';
// import { Home_request } from '../../../../request/Post';
import Loader from '../../../../helper/loader';
// import { Shimmer } from 'react-shimmer'
const Storycontainer = (props) => {
    // const [Loaders, setLoaders] = useState(true)
    // const [homedata, setHomedata] = useState()
    const homedata = props.Storycontainer;
    // console.log(homedata)
    const navigate = useNavigate();
    function storyNavigate() {
        navigate("/stories");
    }

    // useEffect(() => {
    // setLoaders(true);

    // setHomedata(props.Storycontainer);
    // Home_request().then((users) => setHomedata(users.data.webStories));
    // localStorage.setItem('storiess', homedata.data.webStories)

    // setLoaders(false);
    // }, []);
    return (
        <>
            <div className='d-flex scrollable  centerstory align-items-center mt-md-2   mb-2 '>
                <div className='mx-1 '
                >
                    <b className='textbold2 m-0 p-0'>STORIES</b>
                    <div className='textorprimary  m-0 p-0 textbold1 lineheight2 fontsize' style={{color:"#50B167",textAlign:"center"}}>NOW.</div>

                </div>

                {homedata !== undefined ?
                    homedata.map((index, id) => {
                        // let ird = slider
                        // console.log(index.stories === []);
                        let ird = index.thumbnail ? index.thumbnail : slider
                      
                        return (
                            // <div key={index._id} className=' imgcontainer text-center curserpointer' onClick={() => window.open(`/webstories/${index.name}`, "_blank")} >
                            <div key={index._id} className=' imgcontainer text-center curserpointer' onClick={async () => {
                                await localStorage.setItem('storyid', index._id)
                                await window.open(`/webstories/${index.name.replaceAll(' ', '-')}`, "_blank")
                            }} >
                                {/* style={{ 'backgroundImage': `url(${ird})` }} */}
                                {/* <div className='story-now-circle  '  style={{ 'backgroundImage': `url(${ird})` }} */}
                                {/* <div className='rounded-circle  hideimage' */}
                                <div style={{borderRadius:"50%",border:"1px solid #50B167"}}
                                > <img src={ird} className="roundimage" alt={index.name} />
                                    {/* <img src={ird} className="story-now-img w-100" alt={index.name} /> */}
                                </div>
                                <p className='story-now-title textnormal dots'>{index.name}</p>
                            </div>)
                    }) :

                    <><Loader />

                    </>
                }
                {/* <div className='    imgcontainer text-center curserpointer' onClick={() => window.open("/webstories", "_blank")} >



                    <div className='story-now-circle '
                    >

                        <img src={slider} className="story-now-img " alt="s" />
                    </div>
                    <p className='story-now-title textnormal'>Naresh Bhart..</p>
                </div> */}
                {/* <div className='   imgcontainer text-center curserpointer' onClick={() => window.open("/webstories", "_blank")} >
                    <div className='story-now-circle '
                    >

                        <img src={slider} className="story-now-img " alt="s" />
                    </div>
                    <p className='story-now-title textnormal'>Naresh Bhart..</p>
                </div>
                <div className='   imgcontainer text-center curserpointer' onClick={() => window.open("/webstories", "_blank")} >
                    <div className='story-now-circle '
                    >

                        <img src={slider} className="story-now-img " alt="s" />
                    </div>
                    <p className='story-now-title textnormal'>Naresh Bhart..</p>
                </div>
                <div className='   imgcontainer text-center curserpointer' onClick={() => window.open("/webstories", "_blank")} >
                    <div className='story-now-circle '
                    >

                        <img src={slider} className="story-now-img " alt="s" />
                    </div>
                    <p className='story-now-title textnormal'>Naresh Bhart..</p>
                </div>
                <div className='   imgcontainer text-center curserpointer' onClick={() => window.open("/webstories", "_blank")} >
                    <div className='story-now-circle '
                    >

                        <img src={slider} className="story-now-img " alt="s" />
                    </div>
                    <p className='story-now-title textnormal'>Naresh Bhart..</p>
                </div>
                <div className='   imgcontainer text-center curserpointer' onClick={() => window.open("/webstories", "_blank")} >
                    <div className='story-now-circle '
                    >

                        <img src={slider} className="story-now-img " alt="s" />
                    </div>
                    <p className='story-now-title textnormal'>Naresh Bhart..</p>
                </div>
                <div className='   imgcontainer text-center curserpointer' onClick={() => window.open("/webstories", "_blank")} >
                    <div className='story-now-circle '
                    >

                        <img src={slider} className="story-now-img " alt="s" />
                    </div>
                    <p className='story-now-title textnormal'>Naresh Bhart..</p>
                </div>
                <div className='   imgcontainer text-center curserpointer' onClick={() => window.open("/webstories", "_blank")} >
                    <div className='story-now-circle '
                    >

                        <img src={slider} className="story-now-img " alt="s" />
                    </div>
                    <p className='story-now-title textnormal'>Naresh Bhart..</p>
                </div> */}

                <div className='    text-center curserpointer  ' onClick={storyNavigate}>
                    <img src={right} className="   " alt="s" />
                </div>


            </div>
        </>
    );
}

export default Storycontainer;
