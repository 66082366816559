import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
// import { Breadcrumb } from 'react-bootstrap';
// import { AiFillHome } from 'react-icons/ai';
// import { MetaTags } from 'react-meta-tags';
// import Loader from '../../../helper/loader';
import { Roundedshimmer } from '../../../helper/shimmer/roundshimmer';
import ShimmerSmall from '../../../helper/shimmer/shimmersmall';
import { defaultscript, goToTop, homesdata, trendingdatatable } from '../../../helper/simpler_state';
import Layout from '../../../layout';
import { trendingpost } from '../../../request/Post';
// import ButtomFooter from '../../components/Footer/buttom_footer'

// import Header from '../../components/header/header'
import Homepagewraper from './Compnent/homepage_wraper'
// import Slider from './Compnent/slider'
import Storycontainer from './Compnent/storycontainer'

function Home() {
    const [Loaders, setLoaders] = useState(true)
    const homedata = homesdata.use()
    // const [homedata, setHomedata] = useState()
    useEffect(() => {
        goToTop();
        setLoaders(true);
        trendingpost().then((users) => trendingdatatable(users));

        setLoaders(false);
    }, []);

    var scripts = homedata !== false ?
        homedata?.data?.seo
            ?

            homedata?.data?.seo?.scripts
                ? Object.keys(homedata?.data?.seo?.scripts).map((value, id) => { return (id !== undefined ? homedata?.data?.seo?.scripts[value] : defaultscript) })

                // postddetailsdata?.data?.post?.seo?.scripts?.map((value) => { return value })
                :
                defaultscript
            :
            defaultscript
        :
        defaultscript


    // homedata.data ? homedata.data.scripts ? homedata.data.scripts.length > 0 ? homedata.data.scripts.map((value) => { return value }) :
    //     defaultscript
    //     :
    //     defaultscript
    //     :
    //     defaultscript
    //     :
    //     defaultscript
    console.log(homedata?.data?.seo?.scripts);
    return (
        <>
            <Layout>
                <Helmet>
                    <script type="application/ld+json" >

                        {JSON.stringify(scripts)}


                    </script>
                    <meta charSet="utf-8" />


                    <title>{homedata !== false ? homedata.data ? homedata.data.seo ? homedata?.data?.seo?.name : '' : '' : ''} </title>
                    <meta property="og:title" content={homedata?.data?.seo?.name} />
                    <link rel="url" href={homedata?.data?.seo?.url} />
                    <meta name="description" content={
                        homedata?.data?.seo?.description} />
                    <meta name="keywords" content={
                        homedata?.data?.seo?.keywords} />
                    {/* homedata.data.scripts.map((value, index) =>value)  */}
                </Helmet>
                {/* <MetaTags>
                    
                    <meta name="keywords" content="sarkariprep, sarkariprep.in, sarkari naukri result, sarkari job, sarkari result, sarkari exam preparation, government jobs, sarkari jobs portal, rojgar result, sarkari naukri, online test series, sarkari pariksha, govt exams preparation, sarkari exam, sarkari job find, rojgar samachar portal, employment news, govt jobs, sarkari exam result, rojgaralert, sarkari bharti, sarkari prep, job alert, govt job alert, sarkari job, sarkari job alert, sarkariprep job portal, today news headlines, hindi news headlines, job news, education news, sarkari job news headlines, rojgar samachar news, employment news, sarkari result news, freejobalert news" />
                    <meta name="keywords" content="sarkariprep, sarkariprep.in, sarkari naukri result, sarkari job, sarkari result, sarkari exam preparation, government jobs, sarkari jobs portal, rojgar result, sarkari naukri, online test series, sarkari pariksha, govt exams preparation, sarkari exam, sarkari job find, rojgar samachar portal, employment news, govt jobs, sarkari exam result, rojgaralert, sarkari bharti, sarkari prep, job alert, govt job alert, sarkari job, sarkari job alert, sarkariprep job portal, today news headlines, hindi news headlines, job news, education news, sarkari job news headlines, rojgar samachar news, employment news, sarkari result news, freejobalert news" />
                    <meta property="og:title" content="Sarkariprep.in: Sarkari Job, Sarkari Exam Preparation India" />
                    <meta property="og:description" content="Sarkariprep is a sarkari job portal india sarkari prep provides sarkari result, government job, sarkari exam preparation for freshers." />
                    
                </MetaTags> */}
                <div className='row  mx-2 justify-content-center' style={{marginTop:"40px"}}>

                    <div className='col-sm-11 col-md-10 col-lg-9'>
                        {/* <Breadcrumb className='justify-content-start breadcrumb-wrapper'>
                        <Breadcrumb.Item ><AiFillHome /> </Breadcrumb.Item>
                        <Breadcrumb.Item>

                        </Breadcrumb.Item>

                    </Breadcrumb> */}
                        {
                            homedata !== false ?

                                < Storycontainer Storycontainer={homedata.data.webStories} />
                                : <Roundedshimmer />}
                        {
                            homedata !== false ?

                                <Homepagewraper headdata={homedata.data.posts} />
                                : <ShimmerSmall />


                        }

                    </div>
                </div></Layout>
            {/* <ButtomFooter /> */}
        </>
    )
}

export default Home