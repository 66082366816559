import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { subcategoriesapidata } from '../../../../helper/simpler_state';
// import { posts_subcategory_request } from '../../../../request/Post';
import '../jobmegacategory/jobmegacotegorycontainer.css';
function Jobmegacotegorycontainer(props) {
    
    // const navigate = useNavigate();
    // var url2 = localStorage.getItem('subcategoryname');
    // var url1 = localStorage.getItem('categoryname');
    localStorage.setItem('subcategoryid', props.id)
    function subcategoryNavigate() {
        // localStorage.setItem('subcategoryname',props.name)
        // posts_subcategory_request(localStorage.getItem('subcategoryid')).then((users) => subcategoriesapidata(users));
        // navigate(`/${url1}/${props.name.replaceAll(' ', '_')}`);
    }
    // function govjobsNavigate(name, id) {
    //     localStorage.setItem('categoryid', id);
    //     navigate(`/govjobs/${name}`);

    // }
    // function subcategoryNavigate() {
    //     localStorage.setItem('categoryid', `${props.id}`);
    //     navigate(`/govjobs/${props.title}`);
    //     window.location.reload(false);

    // }
    return (
        <>
         <div className='col  containerstates imgcontainer curserpointer textonhover p-1' onClick={subcategoryNavigate}>
                {/* <h1 className='textbold2 mb-0'>{props.hindiname}</h1> */}
                <div className='  row justify-content-center my-3 ' >
                    <div className='col-auto     '   >




                        <>
                            <img src={props.images} className="roundimage  " alt={props.name} />
                        </>
                    </div>

                </div>
                <h1 className='textbold2' style={{textAlign:"center"}}>{props.name}</h1>
            </div>
            {/* text-center */}
                {/* <h1 className='textbold2 mb-0'>{props.hindiname}</h1> */}
            {/* <div     className='col   containerstates curserpointer textonhover p-1' onClick={subcategoryNavigate}>
                <div className='  row justify-content-center my-3 ' >
                    <div className='col-auto image_background   '   >
                        <img src={props.images} className="p-1 h-100 " alt={props.name} />
                    </div>

                </div>
                <h1 className='textbold2'>{props.name}</h1>
            </div> */}

        </>
    );
}

export default Jobmegacotegorycontainer;
