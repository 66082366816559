


import "./App.css";
// Bootstrap CSS


import ProjectRoutes from "./Routes";

function App() {

  return (
    <div>


      <ProjectRoutes />
     
    </div>
  );
}

export default App;
