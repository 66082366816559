
import { entity } from 'simpler-state'

export const counter = entity(0)
export const settrendingdata = entity(false)
export const homesdata = entity(false)
export const subcategoriesdata = entity(false)
export const ableshimmer = entity(false)



// export const reset = () => {
//     settrendingdata.
// }
// export const reset = () => {
//     counter.set(0)
// }

// export const increment = by => {
//     counter.set(value => value + by)
//     // --OR-->  counter.set(counter.get() + by)
// }
export const trendingdatatable = users => {
    settrendingdata.set(users);
}

export const homeapidata = data => {
    homesdata.set(data);

}
export const subcategoriesapidata = data => {
    subcategoriesdata.set(data);
}

export const goToTop = () => window.scrollTo(0, 0);
export const defaultscript = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "url": "https://sarkariprep.in/",
    "name": "Sarkariprep.in: Sarkari Job, Sarkari Exam Preparation India",
    "description": "Sarkariprep is a sarkari job portal india sarkari prep provides sarkari result, government job, sarkari exam preparation for freshers.",
    "publisher": {
        "@type": "Thing",
        "name": "sarkariprep.in"
    }
};