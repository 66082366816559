import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Shimmer } from 'react-shimmer'

export default function Tableshimmer() {
    return (
        <div className="content-grid">
            <Row className='gy-3'>




                <Col sm={6} lg={4} md={4} className=' '>
                    <>
                        <div width={250} className='  '>
                            <Shimmer width={250} height={300} />
                            <hr width={250} className='my-1' /><Shimmer height={20} width={250} />

                        </div>   </>
                </Col>


                <br />
                <Col sm={6} lg={4} md={4} className=' ' >
                    <>
                        <div width={250} className=' '>
                            <Shimmer width={250} height={300} />
                            <hr width={250} className='my-1' /><Shimmer height={20} width={250} />

                        </div>
                    </>
                </Col>

                <Col sm={6} lg={4} md={4} className=' '>
                    <>
                        <div width={250} className=' '>
                            <Shimmer width={250} height={300} />
                            <hr width={250} className='my-1' /><Shimmer height={20} width={250} />

                        </div>
                    </>
                </Col>


                <Col sm={6} lg={4} md={4} className=' '>
                    <>

                        <div width={250} className=' '>
                            <Shimmer width={250} height={300} />
                            <hr width={250} className='my-1' /><Shimmer height={20} width={250} />

                        </div>   </>
                </Col>
                <Col sm={6} lg={4} md={4} className=' '>
                    <>
                        <div width={250} className=' '>
                            <Shimmer width={250} height={300} />
                            <hr width={250} className='my-1' /><Shimmer height={20} width={250} />

                        </div>   </>
                </Col>



                <Col sm={6} lg={4} md={4} className=' '>
                    <>
                        <div width={250} className=' '>
                            <Shimmer width={250} height={300} />
                            <hr width={250} className='my-1' /><Shimmer height={20} width={250} />

                        </div>
                    </>
                </Col>







            </Row>
        </div>
    )
}
