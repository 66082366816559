import React from 'react';
import '../jobcontainer/jobui.css'
import { IoBriefcase } from 'react-icons/io5';
import { FaCalendarAlt } from 'react-icons/fa';
import { AiFillStar } from 'react-icons/ai';
import { HiUser } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
const Jobsui = (props) => {

    const navigate = useNavigate();

    function postdetailsNavigate() {
        localStorage.setItem('postdetailsid', `${props.id}`);
        // navigate(`/${localStorage.getItem('categoryname')}/${localStorage.getItem('categoryname')}/${props.slug.replaceAll(' ', '_')}`);
        navigate(`/${props.slug.replaceAll(' ', '_')}`);
        // window.location.reload(false);

    }
    return (
        <div className='borderradious p-0' onClick={postdetailsNavigate} >
            <div className='text-center my-1    ' onClick={postdetailsNavigate}>
            {/* lineheight */}
                <h1 className='textbold2 textcolorprimary dotcontent'  > {props.head}</h1>
            </div>
            <div className='borderradioustop'>
                <div className='d-flex justify-content-between p-2'>
                    <div className='col-sm-8  testalignmentjustify '>

                        <p className='textnormal m-0 dotcontent'><IoBriefcase /> &nbsp; &nbsp; पद का नाम  - {props.title}</p>
                        <p className='textnormal m-0'><HiUser /> &nbsp; &nbsp; पदों की संख्या  - {props.no}</p>
                        <p className='textnormal m-0 dotcontent'><AiFillStar /> &nbsp; &nbsp; योग्यता - {props.qualification}</p>
                        <p className='textnormal m-0'><FaCalendarAlt /> &nbsp; &nbsp; अंतिम तिथि - {props.date}</p>


                    </div>
                    {/* <div className='col-sm-1 '></div> */}
                    <div className='col-sm-3 align-self-center curserpointer' onClick={postdetailsNavigate}>
                        <div className='buttondeco pb-1'>

                            <div className=' '>
                                <b className=' text-white mx-3 textmorebtn' >अधिक पढ़े </b>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
            <div></div>
        </div>
    );
}

export default Jobsui;
