import React, { useEffect, useState } from 'react';
import Adrectengal from '../../components/adds/Adrectengal';
import { Markup } from 'interweave';
import Head from '../../components/headtype';
import Rightbar from '../../components/rightbar/rightbar';
import Postdetailsheader from './component/postdetailsheader';
import './component/postdetails.css';
import logo from '../../../assets/images/logos.png'
import whatsapp from '../../../assets/images/whatsapp.png'
import telegram from '../../../assets/images/telegram.png'
import facebook from '../../../assets/images/facebook.png'
import twitter from '../../../assets/images/twitter.png'
import { FaTelegramPlane, FaLinkedinIn, FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa';
// import ProgressBar from 'react-progressbar-on-scroll'
// import Jobsui from '../../components/jobscontainers/jobcontainer/jobsui';
// import Headtype1 from '../../components/headtype1';
import { posts_details_request, posts_details_scrollnext } from '../../../request/Post';
// import Loader from '../../../helper/loader';
// import Newsconponent from '../../components/newscontainer/newsconponent';
// import imgnews from '../../../assets/images/news.png';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import { AiFillHome } from 'react-icons/ai';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { defaultscript, goToTop } from '../../../helper/simpler_state';
import Layout from '../../../layout';
import { Helmet } from 'react-helmet';
// import ScreenreaderLabelExample from '../../components/processerbar';
import { Shimmer } from 'react-shimmer';
// import { Linearshimmer } from '../../../helper/shimmer/linearshimmer';
const Postdetails = (props) => {
    // const [pagination, setPagination] = useState(0);
    // const [page, setpage] = useState(1);

    // const [postdata, setpostdata] = useState(true);
    // const [scrollCalled, setscrollCalled] = useState(false);
    // const [handelscrolllcheck, setHandelscrolllcheck] = useState(true);
    const [nextcategory, setNextcategory] = useState('');
    const [nextsubCategory, setNextsubCategory] = useState('');
    const [nextskip, setNextSkip] = useState('');
    const [scrollCheck, setscrollCheck] = useState(true);
    const [shimmervalue, setShimmervalue] = useState(false);
    const [isLoder, setLoaders] = useState(true);
    const [totalpage, setTotalpage] = useState(0);
    const [postddetailsdata, setpostdetailsdata] = useState();
    const navigate = useNavigate();
    let userId = useParams();
    const [skipdata, setSkipdata] = useState({
    });
    const [addpage, setAddpage] = useState([]);
    useEffect(() => {


        goToTop();
        setLoaders(true);
        // posts_request(localStorage.getItem('categoryid')).then((users) => setpostdata(users));
        posts_details_request(localStorage.getItem('postdetailsid')).then((details) => {
            setpostdetailsdata(details);
            //for next
            setNextcategory(details?.data?.infiniteRecords?.category ? details?.data?.infiniteRecords?.category : '');
            setNextsubCategory(details?.data?.infiniteRecords?.subCategory ? details?.data?.infiniteRecords?.subCategory : '');
            setNextSkip(details?.data?.infiniteRecords?.skip.toString());
            setTotalpage(details?.data?.infiniteRecords?.skip);
            //
            setSkipdata(details?.data?.infiniteRecords)
            setTimeout(() => {
                console.log(details?.data?.infiniteRecords)
            }, 2000);
        });

        setLoaders(false);
    }, [userId.name]);

    const baseurl = 'https://sarkariprepweb.proapp.in';
    const location = useLocation();

    const update = postddetailsdata !== undefined ? postddetailsdata?.data?.post?.updatedAt.split("T") : '';
    // postddetailsdata?.data?.post?
    var postdatacontent = postddetailsdata?.data?.post?.description?.content ? postddetailsdata?.data?.post?.description?.content : <></>;
   

    var scripts = postddetailsdata !== undefined ?
        postddetailsdata?.data?.post?.seo
            ?

            postddetailsdata?.data?.post?.seo?.scripts
                ? Object.keys(postddetailsdata?.data?.post?.seo?.scripts).map((value, id) => { return (id !== undefined ? postddetailsdata?.data?.post?.seo?.scripts[value] : defaultscript) })

                // postddetailsdata?.data?.post?.seo?.scripts?.map((value) => { return value })
                :
                defaultscript
            :
            defaultscript
        :
        defaultscript

    // const mainhandleScroll = async () => {
    //     setscrollCalled(true);
    //     console.log('ok');
    //     skiphandel(skipdata);
    // }
    // const [executed, setExecuted] = useState(false);
    // var something = (function () {
    //     return function () {
    //         if (!executed) {
    //             setExecuted(true);
    //             mainhandleScroll();
    //         } else {
    //             console.log(executed);

    //         }
    //     };
    // })();
    // const handleScroll = async () => {

    //     if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight - 120) {

    //         if (handelscrolllcheck) {
    //             console.log(scrollCalled);
    //             something();
    //             goToTop();

    //         }
    //     }


    // }
    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);

    // }, []);
    // const repeatcall = () => { skiphandel(skipdata); }


    // const skiphandel = (

    // ) => {

    //     posts_details_scroll(skipdata).then((user) => {
    //         setAddpage([...addpage, user.data.post])
    //         setHandelscrolllcheck(user.success)
    //         setSkipdata(user?.data?.infiniteRecords)
    //     });
    // }
    const [nowa, setNowa] = useState(0);
    function currentScrollPercentage() {
        setNowa(Math.round((document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight) * 100));
    }
    useEffect(() => {
        window.addEventListener('scroll', currentScrollPercentage);
    }, []);


    if (nowa === 80 && scrollCheck) {
        setTimeout(() => {
            setShimmervalue(true);
        }, 0.1);
        console.log('executed');
        for (let index = 0; index < 1; index++) {
            // goToTop();
            posts_details_scrollnext(nextcategory, nextsubCategory, nextskip).then((res) => {
                console.log(res.success);
                setTimeout(() => {
                    setShimmervalue(false);
                }, 0.1);
                if (res.success) {
                    setNextcategory(res?.data?.infiniteRecords?.category ? res?.data?.infiniteRecords?.category : '');
                    setNextsubCategory(res?.data?.infiniteRecords?.subCategory ? res?.data?.infiniteRecords?.subCategory : '');
                    setNextSkip(res?.data?.infiniteRecords?.skip?.toString());
                    setAddpage([...addpage, res?.data?.post])
                } else {
                    setscrollCheck(res.success);
                }

            }).catch((err) => { console.log(err) }).finally(() => { });
        }
    }
    return (<>

        <Layout>
            <></>
            <></>
            <></>

            <Helmet>
                <script type="application/ld+json" >

                    {JSON.stringify(scripts)}
                    {/* 
                    postddetailsdata.data.post.seo */}
                </script>
                <title> {postddetailsdata !== undefined ? postddetailsdata.data ? postddetailsdata.data.post ? postddetailsdata.data.post.seo ? postddetailsdata.data.post.seo.title : '' : '' : '' : ''}
                </title>
                {/* <title> {postddetailsdata !== undefined ? postddetailsdata.data ? postddetailsdata.data.post ? postddetailsdata.data.post.seo ? postdata?.data?.seo?.title : '' : '' : '' : ''}
                </title> */}
                <meta charSet="utf-8" />
                <meta name="description" content={
                    postddetailsdata?.data?.post?.seo?.description} />

                <meta name="keywords" content={
                    postddetailsdata?.data?.post?.seo?.keywords} />
                <link rel="canonical" href={postddetailsdata?.data?.post?.seo?.url} />
            </Helmet>


            {


<div className='d-flex scrollable  centerstory align-items-center mt-md-2   mb-2 '>
<div className='mx-1 '
>
                    <div className='row  postdetailsecontainer justify-content-center '>
                        <div className='col-sm-11 col-md-10 col-lg-9'>
                            <div className='my-2 row'>

                                <Breadcrumb className='justify-content-start breadcrumb-wrapper'>
                                    <Breadcrumb.Item className='fontsizeBreadcrumb' ><AiFillHome onClick={() => navigate("/")} /> </Breadcrumb.Item>
                                    <Breadcrumb.Item className='fontsizeBreadcrumb' onClick={() => navigate(-1)} > {localStorage.getItem('categoryname')} </Breadcrumb.Item>
                                    <Breadcrumb.Item className='fontsizeBreadcrumb '>
                                        {userId.name}
                                    </Breadcrumb.Item>

                                </Breadcrumb>

                                {postddetailsdata === undefined
                                    ?
                                    <>

                                    </> :
                                    <Postdetailsheader postdetails={postddetailsdata?.data?.post?.heading} />}
                                {/* <Postdetailsheader postdetails='Patwari Jobs 2022 | पटवारी भर्ती 391 पदों पर 12वीं व स्नातक पास, ऐसे करे आवेदन' /> */}
                                {postddetailsdata === undefined
                                    ?
                                    <> <Shimmer width={700} height={800} />
                                    </> : <div className='col-sm-12 col-md-12 col-lg-8  my-2'>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr >
                                                    <th>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='col '>
                                                                <div className='d-flex p-0 m-0 align-items-center '>
                                                                    <div className='col-4 col-md-2 col-sm-4 col-lg-2   ' style={{ 'marginRight': '5px' }}>
                                                                        <img className='   ' src={logo} alt="" />
                                                                    </div>
                                                                    {/* <div className='col-2 p-0 m-0'>
                                                                        <img className='imgtable  ' src={logo} alt="" />
                                                                    </div> */}
                                                                    <div className=' col text-start align-content-center'>
                                                                        < div className='textnormal '>Sakariprep</div>
                                                                        < div className='inlinetext textnormal'>Updated on {update !== undefined ? update[0] : ''}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col  mx-2 '>
                                                                <div className='row  justify-content-end '>
                                                                    <div className='col-4 col-md-2 col-sm-4 col-lg-2'
                                                                    >

                                                                        <img className=' socialmediaimg  curserpointer' src={whatsapp} alt="" onClick={() => window.open(`https://web.whatsapp.com/send?text=${baseurl}${location.pathname}`, "_blank")} />
                                                                    </div>
                                                                    <div className='col-2 d-none d-md-block d-lg-block' >

                                                                        <img className='socialmediaimg curserpointer   ' onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${baseurl}${location.pathname}`, "_blank")} src={facebook} alt="" />
                                                                    </div>
                                                                    <div className='col-2 d-none d-md-block d-lg-block'>

                                                                        <img className='socialmediaimg   curserpointer ' onClick={() => window.open(`https://twitter.com/intent/tweet?text=${baseurl}${location.pathname}`, "_blank")} src={twitter} alt="" />
                                                                    </div>
                                                                    <div className='col-4 col-md-2 col-sm-4 col-lg-2'>

                                                                        <img className='socialmediaimg  curserpointer ' onClick={() => window.open(`https://t.me/share/url?url=${baseurl}${location.pathname}?text=${postddetailsdata.data.post.heading}`, "_blank")} src={telegram} alt="" />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">
                                                        {/* <div className=' textnormal texttablebody '>
                                                            {postddetailsdata.data.post?.seo ? postddetailsdata.data.post?.seo.description : ''}

                                                        </div> */}
                                                        <div className='my-1 text-center'>
                                                            <img height={100} src={postddetailsdata?.data?.post?.image} alt={postddetailsdata.data.heading} />

                                                        </div>
                                                        <div className=' textnormal texttablebody'>
                                                            {postddetailsdata?.data?.post.seo ? postddetailsdata?.data?.post?.seo?.description : ''}

                                                        </div>
                                                        <div className='my-1'>

                                                            <Adrectengal />
                                                        </div>
                                                        {/* {console.log(postdatacontent)} */}
                                                        <div className=' textnormal texttablebody'>
                                                            {/* {doc.firstChild.innerHTML} */}
                                                            {postdatacontent.props === undefined ?

                                                                <Markup className='  articleBody     mx-2' content={postdatacontent} /> : <></>
                                                            }
                                                        </div>


                                                    </td>

                                                </tr>
                                                {/* <tr>
                                                    <th scope="row ">
                                                        <table class="table table-bordered">

                                                            <tbody className='widghtcernter'>
                                                                <tr>
                                                                    <td className=' textnormal widthtbody'  >विभाग</td>
                                                                    <td className=' textnormal widthtbody '>यूपी पंचायती राज विभाग</td>

                                                                </tr>
                                                                <tr>
                                                                    <td className=' textnormal widthtbody ' >भर्ती बोर्ड</td>
                                                                    <td className=' textnormal widthtbody'>राज्य अधीनस्थ चयन आयोग</td>

                                                                </tr>
                                                                <tr>
                                                                    <td className=' textnormal widthtbody' >पद</td>
                                                                    <td className=' textnormal widthtbody'>ग्राम पंचायत सचिव</td>

                                                                </tr>
                                                                <tr>
                                                                    <td className=' textnormal widthtbody' >कुल वैकेंसी</td>
                                                                    <td className=' textnormal widthtbody'>3000</td>

                                                                </tr>
                                                                <tr>
                                                                    <td className=' textnormal widthtbody' >श्रेणी</td>
                                                                    <td className=' textnormal widthtbody'>Upcoming Jobs</td>

                                                                </tr>
                                                                <tr>
                                                                    <td className=' textnormal widthtbody' >आवेदन प्रक्रिया</td>
                                                                    <td className=' textnormal widthtbody'>ऑनलाइन</td>

                                                                </tr>
                                                                <tr>
                                                                    <td className=' textnormal widthtbody' >स्थान</td>
                                                                    <td className=' textnormal widthtbody'>उत्तर प्रदेश</td>

                                                                </tr>
                                                                <tr>
                                                                    <td className=' textnormal widthtbody' >आधिकारिक साइट</td>
                                                                    <td className=' textnormal widthtbody'>upsssc.gov.in</td>

                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </th>

                                                </tr> */}
                                                {/* <tr>
                                                    <th scope="row ">
                                                        {postddetailsdata.data.description ?

                                                            <div className='text-center textbold2'>{postddetailsdata.datapost.description.heading}
                                                            </div> : <div className='text-center textbold2'>
                                                            </div>}
                                                    </th>

                                                </tr> */}




                                            </tbody>
                                        </table>
                                        {
                                            addpage !== [] ? addpage[0] ?

                                                // console.log(postdata.data)
                                                addpage.map((index, id) => {
                                                    const update = index.updatedAt.split("T")
                                                    const addsid = id % 3
                                                    return (
                                                        <>
                                                            <Head head='नई पोस्ट' />
                                                            <Postdetailsheader postdetails={index.heading} />
                                                            <table className='table table-bordered'>
                                                                <thead>
                                                                    <tr >
                                                                        <th>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='col '>
                                                                                    <div className='d-flex p-0 m-0 align-items-center '>
                                                                                        <div className='col-4 col-md-2 col-sm-4 col-lg-2   ' style={{ 'marginRight': '5px' }}>
                                                                                            <img className='   ' src={logo} alt="" />
                                                                                        </div>
                                                                                        <div className=' col text-start align-content-center'>
                                                                                            < div className='textnormal '>Sakariprep</div>
                                                                                            < div className='inlinetext textnormal'>Updated on {update !== undefined ? update[0] : ''}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col  mx-2 '>
                                                                                    <div className='row  justify-content-end '>
                                                                                        <div className='col-4 col-md-2 col-sm-4 col-lg-2'
                                                                                        >

                                                                                            <img className=' socialmediaimg  curserpointer' src={whatsapp} alt="" onClick={() => window.open(`https://web.whatsapp.com/send?text=${baseurl}${location.pathname}`, "_blank")} />
                                                                                        </div>
                                                                                        <div className='col-2 d-none d-md-block d-lg-block'>

                                                                                            <img className='socialmediaimg curserpointer   ' onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${baseurl}${location.pathname}`, "_blank")} src={facebook} alt="" />
                                                                                        </div>
                                                                                        <div className='col-2 d-none d-md-block d-lg-block'>

                                                                                            <img className='socialmediaimg   curserpointer ' onClick={() => window.open(`https://twitter.com/intent/tweet?text=${baseurl}${location.pathname}`, "_blank")} src={twitter} alt="" />
                                                                                        </div>
                                                                                        <div className='col-4 col-md-2 col-sm-4 col-lg-2'>

                                                                                            <img className='socialmediaimg  curserpointer ' onClick={() => window.open(`https://t.me/share/url?url=${baseurl}${location.pathname}?text=${postddetailsdata.data.post.heading}`, "_blank")} src={telegram} alt="" />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td scope="row ">
                                                                            <div className=' textnormal texttablebody '>
                                                                                {/* Up Gram Panchayat Sachiv Jobs 2022 यूपी पंचायती राज विभाग में जल्द ही 12वीं पास के लिए 3000 ग्राम पंचायत सचिव पदों पर भर्ती करने वाले हैं। Up Sarkari Naukri की तैयारी कर रहे अभ्यर्थियों के लिए खुशखबरी, दरअसल हाल ही में यूपी पंचायती राज विभाग में रिक्त 3000 ग्राम पंचायत सचिव पदों की पूर्ति के लिए राज्य अधीनस्थ चयन आयोग को निर्देश जारी कर दिया है। Up Gram Panchayat Sachiv Bharti 2022 की नोटिफिकेशन जल्द ही विभाग द्वारा जारी किया जावेगा। इस लेख के माध्यम से अभ्यार्थी यूपी ग्राम पंचायत सचिव भर्ती 2022 की विस्तृत जानकारी प्राप्त कर सकते हैं। */}
                                                                                {index.seo ? index?.seo.description : ''}

                                                                            </div>
                                                                            <div className='my-1 text-center'>
                                                                                <img height={100} src={index?.image} alt={index.heading} />

                                                                            </div>
                                                                            <div className=' textnormal texttablebody'>
                                                                                {index.seo ? index.seo.description : ''}

                                                                            </div>
                                                                            <div className='my-1'>

                                                                                <Adrectengal />
                                                                            </div>
                                                                            <div className=' textnormal texttablebody'>
                                                                                {/* {doc.firstChild.innerHTML} */}
                                                                                {/* {index?.description.content=== undefined ?
                                                                                    <Markup className='table table-bordered' content={index?.description.content} /> : <></>
                                                                                } */}
                                                                                <Markup className='articleBody' content={index?.description.content} />
                                                                            </div>


                                                                        </td>

                                                                    </tr>
                                                                </tbody> </table>    </>
                                                    )
                                                })
                                                : <>  </>
                                                : <> </>
                                        }


                                        <section className='my-2'>

                                            {shimmervalue ?
                                                <>
                                                    <Head head='नई पोस्ट' />
                                                    <Postdetailsheader postdetails={' '} />
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr >
                                                                <th>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className='col '>
                                                                            <div className='d-flex p-0 m-0 align-items-center '>
                                                                                <div className='col-2 p-0 m-0'>
                                                                                    <img className='imgtable  ' src={logo} alt="" />
                                                                                </div>
                                                                                <div className=' col text-start align-content-center'>
                                                                                    < div className='textnormal '>Sakariprep</div>
                                                                                    < div className='inlinetext textnormal'>Updated on {update !== undefined ? update[0] : ''}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col  mx-2 '>
                                                                            <div className='row  justify-content-end '>
                                                                                <div className='col-2'
                                                                                >

                                                                                    <img className=' socialmediaimg  curserpointer' src={whatsapp} alt="" onClick={() => window.open(`https://web.whatsapp.com/send?text=${baseurl}${location.pathname}`, "_blank")} />
                                                                                </div>
                                                                                <div className='col-2'>

                                                                                    <img className='socialmediaimg curserpointer   ' onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${baseurl}${location.pathname}`, "_blank")} src={facebook} alt="" />
                                                                                </div>
                                                                                <div className='col-2'>

                                                                                    <img className='socialmediaimg   curserpointer ' onClick={() => window.open(`https://twitter.com/intent/tweet?text=${baseurl}${location.pathname}`, "_blank")} src={twitter} alt="" />
                                                                                </div>
                                                                                <div className='col-2'>

                                                                                    <img className='socialmediaimg  curserpointer ' onClick={() => window.open(`https://t.me/share/url?url=${baseurl}${location.pathname}?text=${postddetailsdata.data.post.heading}`, "_blank")} src={telegram} alt="" />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td scope="row ">
                                                                    <div className=' textnormal texttablebody '>
                                                                        {/* Up Gram Panchayat Sachiv Jobs 2022 यूपी पंचायती राज विभाग में जल्द ही 12वीं पास के लिए 3000 ग्राम पंचायत सचिव पदों पर भर्ती करने वाले हैं। Up Sarkari Naukri की तैयारी कर रहे अभ्यर्थियों के लिए खुशखबरी, दरअसल हाल ही में यूपी पंचायती राज विभाग में रिक्त 3000 ग्राम पंचायत सचिव पदों की पूर्ति के लिए राज्य अधीनस्थ चयन आयोग को निर्देश जारी कर दिया है। Up Gram Panchayat Sachiv Bharti 2022 की नोटिफिकेशन जल्द ही विभाग द्वारा जारी किया जावेगा। इस लेख के माध्यम से अभ्यार्थी यूपी ग्राम पंचायत सचिव भर्ती 2022 की विस्तृत जानकारी प्राप्त कर सकते हैं। */}
                                                                        {/* {index.seo ? index?.seo.description : ''} */}

                                                                    </div>
                                                                    <div className='my-1 text-center'>

                                                                        {/* <img height={100} src={index?.image} alt={index.heading} /> */}

                                                                    </div>
                                                                    <div className=' textnormal texttablebody '>
                                                                        {/* {index.seo ? index.seo.description : ''} */}
                                                                        <div className='ml-1'>
                                                                            <Row className=''
                                                                            >
                                                                                <Col  >
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                </Col>
                                                                                <Col   >
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                </Col>
                                                                                <Col>
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                </Col>
                                                                                <Col className='d-none d-md-block d-lg-block'>
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                </Col>
                                                                                <Col className='d-none d-md-block d-lg-block' >
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                </Col>

                                                                            </Row>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row d-flex justify-content-center text-center'>


                                                                        <div className='col-4 '>

                                                                            <Shimmer height={100} width={200} className=' col-4 ' />
                                                                        </div>
                                                                    </div>

                                                                    <div className='my-1'>

                                                                        <Adrectengal />
                                                                    </div>
                                                                    <div className=' textnormal texttablebody'>
                                                                        <div className='ml-1'>

                                                                            <Row className=''
                                                                            >
                                                                                <Col  >
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                </Col>
                                                                                <Col   >
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                </Col>
                                                                                <Col>
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                </Col>
                                                                                <Col className='d-none d-md-block d-lg-block'>
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                </Col>
                                                                                <Col className='d-none d-md-block d-lg-block' >
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                    <Shimmer height={10} width={80} /> &nbsp; &nbsp;
                                                                                </Col>

                                                                            </Row>
                                                                        </div>
                                                                    </div>


                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </> : <></>}
                                        </section>
                                        <>
                                            <Head head='हमें सोशल मीडिया पर फॉलो करे' />
                                        </>


                                        <div className='d-flex flex-row  justify-content-center   '>

                                            <div className='iconfa  ' onClick={() => window.open(`https://t.me/share/url?url=${baseurl}${location.pathname}?text=${postddetailsdata.data.post.heading}`, "_blank")}     >

                                                <FaTelegramPlane />
                                            </div>
                                            <div className='iconfa' onClick={() => window.open(`https://www.linkedin.com/`, "_blank")}    >
                                                <FaLinkedinIn />
                                            </div>
                                            <div className='iconfa' onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${baseurl}${location.pathname}`, "_blank")}   >
                                                <FaFacebookF />
                                            </div>
                                            <div className='iconfa' onClick={() => window.open(`https://twitter.com/intent/tweet?text=${baseurl}${location.pathname}`, "_blank")}   >
                                                <FaTwitter />
                                            </div>
                                            <div className='iconfa' onClick={() => window.open(`https://www.youtube.com/`, "_blank")}    >
                                                <FaYoutube />
                                            </div>

                                        </div>
                                    </div>}
                                {
                                }

                                <div className='d-none d-md-block d-lg-block col-sm-12 col-md-12 col-lg-4'>
                                    <Rightbar />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                </div>

            }


        </Layout>
    </>
    );
}

export default Postdetails;
