import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Adsquare from '../adds/Adsquare';
import Headtype1 from '../headtype1';
import '../../screen/Home/Compnent/homepage_wraper.css';
// import { trendingpost } from '../../../request/Post';
import { settrendingdata, trendingdatatable } from '../../../helper/simpler_state';
import { trendingpost } from '../../../request/Post';
// import AdSence from 'react-adsense';
// import { Shimmer } from 'react-shimmer';
import Tablesrhimmer from '../../../helper/shimmer/tablesrhimmer';

const Rightbar = () => {
    const navigate = useNavigate();
    function postdetailsNavigate(id, title) {
        localStorage.setItem('postdetailsid', `${id}`);
        navigate(`/${title}`);
        // window.location.reload(false);

    }


    const [isLoder, setLoaders] = useState(true);
    // const [trendingdata, settrendingdata] = useState(true);
    // trendingpost
    useEffect(() => {
        setLoaders(true);
        // if (settrendingdata === false) {
        trendingpost().then((users) => trendingdatatable(users));
        // }


        // trendingpost().then((users) => settrendingdata(users));
        setLoaders(false);
    }, []);
    const trending = settrendingdata.use()
    return (
        <div>
            <div className=''>

                <Adsquare />
                <Headtype1 head='ट्रेंडिंग ' />

                <div className='my-3'>

                    <table className="table table-bordered  ">

                        <tbody >
                            {trending !== false ?

                                trending.data.map((index, id) => {
                                    return (
                                        <tr key={id} onClick={() => {
                                            console.log('huhyuhy');
                                        }}>

                                            <td key={index._id} className='d-flex flex-row m-0' onClick={() => index?.seo?.slug ? postdetailsNavigate(index._id, index.seo.slug) : ''} >
                                                <div>   <div className='green d-flex   '></div></div>
                                                <h6 className=' textonhover textnormal1  twolinesdot'>
                                                    {index.heading}
                                                </h6>
                                            </td>
                                        </tr>)
                                })
                                : <Tablesrhimmer pwidth={295} />

                            }





                        </tbody>
                    </table>
                </div>
                {/* <Adsquare /> */}
            </div>
        </div>
    );
}

export default Rightbar;
