import React from 'react'
import { Row } from 'react-bootstrap'
import { Shimmer } from 'react-shimmer'
import right from "../../assets/images/right.png";
// const right = React.lazy(() => import('../../assets/images/right.png'));
export const Roundedshimmer = () => {
    return (
        <>
            <div className='d-flex scrollable  centerstory align-items-center mb-4 mb-md-4  '>

                <div className='mx-1 col '
                >
                    <b className='textbold2 m-0 p-0'>STORIES</b>
                    <div className='textorprimary  m-0 p-0 textbold1 lineheight2 fontsize'>NOW.</div>

                </div>
                {/* &nbsp; &nbsp;                &nbsp; &nbsp; */}
                &nbsp; &nbsp;                &nbsp; &nbsp;
                <Shimmer height={80} width={80} className='rounded-circle col-4 ' />
                &nbsp; &nbsp;    <Shimmer height={80} width={80} className='rounded-circle col-4 ' />
                &nbsp; &nbsp;    <Shimmer height={80} width={80} className='rounded-circle col-4 ' />
                &nbsp; &nbsp;    <Shimmer height={80} width={80} className='rounded-circle col-4 ' />
                &nbsp; &nbsp;    <Shimmer height={80} width={80} className='rounded-circle col-4 ' />
                &nbsp; &nbsp;    <Shimmer height={80} width={80} className='rounded-circle col-4 ' />
                &nbsp; &nbsp;    <Shimmer height={80} width={80} className='rounded-circle col-4 ' />
                &nbsp; &nbsp;    <Shimmer height={80} width={80} className='rounded-circle col-4 ' />

                <div className='    text-center curserpointer  col' >
                    <img src={right} className="   " alt="s" />
                </div>
            </div>
        </>
    )
}
