// import React, { useEffect, useState } from 'react';
// import pulish from '../../../assets/images/pulish.png';
// import Headtype1 from '../../components/headtype1';
// // import Header from '../../components/header/header';
// // import slider from "../../../assets/images/sliderimage.png";
// // import pulish1 from '../../../assets/images/police1.png';
// // import bank from '../../../assets/images/bank.png';
// // import train from '../../../assets/images/train.png';
// // import psc from '../../../assets/images/psc.png';
// // import teaching from '../../../assets/images/teaching.png';
// import Jobmegacotegorycontainer from '../../components/jobscontainers/jobmegacategory/jobmegacotegorycontainer';
// import Head from '../../components/headtype';
// import Jobsui from '../../components/jobscontainers/jobcontainer/jobsui';
// // import Adsquare from '../../components/adds/Adsquare';
// import { MetaTags } from 'react-meta-tags';
// import Rightbar from '../../components/rightbar/rightbar';
// import { posts_request } from '../../../request/Post';
// import Loader from '../../../helper/loader';
// import Newsconponent from '../../components/newscontainer/newsconponent';
// // import imgnews from '../../../assets/images/news.png';
// import '../../components/newscontainer/news.css';
// import { Breadcrumb } from 'react-bootstrap';
// import { useNavigate, useParams } from 'react-router-dom';
// import { AiFillHome } from 'react-icons/ai';
// import Adrectengal from '../../components/adds/Adrectengal';
// import { goToTop } from '../../../helper/simpler_state';
// const Govjobs = (props) => {
//     // const [isLoder, setLoaders] = useState(true);
//     const [postdata, setpostdata] = useState(true);
//     let userId = useParams();
//     useEffect(() => {
//         // setLoaders(true);
//         goToTop();
//         posts_request(localStorage.getItem('categoryid')).then((users) => setpostdata(users));
//         // setLoaders(false);
//     }, [userId]);
//     const navigate = useNavigate();

//     localStorage.setItem('categoryname', userId.name);
//     // const location = useLocation();
//     // console.log(props.category) 
//     return (<>

//         <MetaTags>
//             <title>Sarkariprep:{userId.name}</title>
//             <meta name="description" content="Government is a sarkari Naukri portal india sarkari prep provides sarkari result, government job, sarkari exam preparation for freshers." />
//             <meta name="keywords" content="Government  Yojana 2022, Government Yojana, सरकारी योजना, सरकारी योजना हिंदी, Central Government Schemes, State Government Sarkari Yojana, Sarkari Yojana List 2022, Sarkari Yojana List Pdf, All India Sarkari Yojana, Government Schemes Hindi, Sarkari Yojana List of All States, Latest Government Schemes Hindi, Sarkariyojana Portal, सरकारी योजना रजिस्ट्रेशन, Sarkari Yojana Online Form, Sarkariprep" />
//             <meta name="keywords" content="Government,  Government naukri result, Government Naukri, Government result, sarkari exam preparation, government jobs, sarkari jobs portal, rojgar result, sarkari naukri, online test series, sarkari pariksha, govt exams preparation, sarkari exam, sarkari job find, rojgar samachar portal, employment news, govt jobs, sarkari exam result, rojgaralert, sarkari bharti, sarkari prep, job alert, govt job alert, sarkari job, sarkari job alert, sarkariprep job portal, today news headlines, hindi news headlines, job news, education news, sarkari job news headlines, rojgar samachar news, employment news, sarkari result news, freejobalert news" />
//             <meta property="og:title" content="Sarkariprep : Government Naukri, Government Naukri India" />
//             {/* <meta property="og:image" content="path/to/image.jpg" /> */}
//         </MetaTags>


//         <div className='row  justify-content-center'>
//             <div className='col-sm-12 col-md-8 postdetailsecontainer'>
//                 <div className='container mx-1'>
//                     <Breadcrumb className='justify-content-start breadcrumb-wrapper'>
//                         <Breadcrumb.Item ><AiFillHome onClick={() => navigate("/")} /> </Breadcrumb.Item>
//                         <Breadcrumb.Item    >
//                             {userId.name.replaceAll('_', ' ')}
//                         </Breadcrumb.Item>
//                     </Breadcrumb>
//                     <Headtype1 head={userId.name.replaceAll('_', ' ')} />
//                     <div className='section my-2 row   '>
//                         <div className=' col-md-8  '>

//                             <div className='row'>
//                                 {postdata.data ?

//                                     postdata.data.subcategories ? postdata.data.subcategories.map((index, id) => {
//                                         let ird = index.icon === undefined ? pulish : index.icon
//                                         console.log();
//                                         //    localStorage.setItem('subcategoryname',index.name)
//                                         return (

//                                             <div key={index._id} className='col-sm-6 col-6 col-md-4  col-lg-3  curserpointer '>

//                                                 <Jobmegacotegorycontainer name={index ? index.name : ''} id={index ? index._id : ''} title={index ? index.name : ''} hindiname={index.seo ? index.seo.title : ''} images={ird} />
//                                             </div>

//                                         )
//                                     }
//                                     ) : console.log('')

//                                     : <><Loader /></>}





//                                 {

//                                     postdata.data ?


//                                         postdata.data.subcategories && postdata.data.subcategories.length > 0
//                                             ? <div className='mx-1'>

//                                                 <Head head={userId.name.replaceAll('_', ' ')} />
//                                             </div> : console.log(postdata.data.subcategories.length) : <></>
//                                 }


//                                 {
//                                     postdata.data ?

//                                         postdata.data.data.map((index, id) => {
//                                             const update = index.updatedAt.split("T")
//                                             if (id === 0) {
//                                                 id = 1
//                                             }
//                                             const addsid = id % 3

//                                             return (
//                                                 <>

//                                                     <div key={index._id} >

//                                                         {/* {index.description.heading} */}
//                                                         {index.type === 'Job' ?
//                                                             <Jobsui id={index._id} head={index.description ? index.description.heading : ''} qualification={index.description ? index.description.qualifications : ''} date={index.description ? index.description.last_date : ''} no={index.description ? index.description.no_of_posts : ''} title={index.description ? index.description.short_heading : ''} />
//                                                             :
//                                                             <Newsconponent id={index._id} className='leftalignsaprate' news={index.image} title={index.heading} type='न्यूज़' Published={`Published on - ${update[0]}`} />}
//                                                     </div>

//                                                     {addsid === 3 ? <><Adrectengal /></> : <></>}
//                                                 </>
//                                             )
//                                         })
//                                         : <><Loader /></>

//                                 }
//                                 {/* <Newsconponent id={'index._id'} className='leftalignsaprate' news={imgnews} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='न्यूज़' Published="Published on Oct 07, 2022" /> */}
//                             </div>
//                         </div>

//                         <div className='col-md-4 col-12 col-sm-12 '>
//                             <Rightbar />
//                         </div>
//                     </div>

//                 </div>
//             </div>
//         </div>

//     </>
//     );
// }

// export default Govjobs;

import React, { useEffect, useState } from 'react';
import pulish from '../../../assets/images/pulish.png';
import Headtype1 from '../../components/headtype1';
// import Header from '../../components/header/header';
// import slider from "../../../assets/images/sliderimage.png";
// import pulish1 from '../../../assets/images/police1.png';
// import bank from '../../../assets/images/bank.png';
// import train from '../../../assets/images/train.png';
// import psc from '../../../assets/images/psc.png';
// import teaching from '../../../assets/images/teaching.png';
// import Adsquare from '../../components/adds/Adsquare';
import Jobmegacotegorycontainer from '../../components/jobscontainers/jobmegacategory/jobmegacotegorycontainer';
import Head from '../../components/headtype';
import Jobsui from '../../components/jobscontainers/jobcontainer/jobsui';
// import { MetaTags } from 'react-meta-tags';
import Rightbar from '../../components/rightbar/rightbar';
import { posts_request } from '../../../request/Post';
// import Loader from '../../../helper/loader';
import Newsconponent from '../../components/newscontainer/newsconponent';
import nodata from '../../../assets/images/nodatapng.png';
import '../../components/newscontainer/news.css';
import { Breadcrumb, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { AiFillHome } from 'react-icons/ai';
import Adrectengal from '../../components/adds/Adrectengal';
import { defaultscript, goToTop } from '../../../helper/simpler_state';
import Layout from '../../../layout';
import { Helmet } from 'react-helmet';
import JobsuiShimmer from '../../../helper/shimmer/jobsuishimmer';
const Govjobs = (props) => {
    // const [isLoder, setLoaders] = useState(true);
    const [postdata, setpostdata] = useState(true);
    const [page, setpage] = useState(1);
    const [demopage, setdemopage] = useState([]);
    const [pagination, setPagination] = useState(1);
    const [scrollCalled, setscrollCalled] = useState(false);
    const [totalpage, setTotalpage] = useState(2);
    // const [currentPage, setCurrentPage] = useState(1);
    let userId = useParams();
    // useEffect(() => {

    // }, []);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);
    useEffect(() => {
        setdemopage([]);
        // setLoaders(true);
        goToTop();
 
        posts_request(localStorage.getItem('categoryid'), 1).then((users) => {
            setpostdata(users)
            console.log(localStorage.getItem('categoryid'))
           
       
            // setsubcategoriespost(users)
            // setdemopage([...demopage, ...users.data.data])
            setdemopage([...users.data.data])
            setTotalpage(users.data.dataRecords.totalPages)
        }

        );
        return () => {

            setPagination(1);
        };

        // setLoaders(false);
    }, [userId]);


    useEffect(() => {
        // setLoaders(true);
        // goToTop();
        if (pagination !== 1) {
            console.log('ok');
            posts_request(localStorage.getItem('categoryid'), pagination).then((users) => {
                setpostdata(users)
                // setsubcategoriespost(users)
                setdemopage([...demopage, ...users.data.data])
            }

            );
        }

        // setLoaders(false);
    }, [pagination]);
    const handleScroll = async () => {

        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight - 120) {
            setpage(prev => prev + 1);
            console.warn(totalpage, 'totalpage');

            if (pagination <= totalpage) {
                setscrollCalled(true);
                setPagination(pagination + 1);

                //    await fetchdata(pagination);

            }


            // posts_request(localStorage.getItem('categoryid')).then((users) => setpostdata(users));
        }
    }

    function subcategoryNavigate(id, name) {
        var url1 = localStorage.getItem('categoryname');
        localStorage.setItem('subcategoryid', id)
        localStorage.setItem('subcategoryname', name)
        // posts_subcategory_request(localStorage.getItem('subcategoryid')).then((users) => subcategoriesapidata(users));
        navigate(`/${url1}/${name.replaceAll(' ', '_')}`);
    }

    var scripts = postdata !== true ?
        postdata?.data?.seo
            ?

            postdata?.data?.seo?.scripts
                ? Object.keys(postdata?.data?.seo?.scripts).map((value, id) => { return (id !== undefined ? postdata?.data?.seo?.scripts[value] : 
                    
                    defaultscript) })
                :
                defaultscript
            :
            defaultscript
        :
        defaultscript

    // postdata.data.seoScripts ? postdata.data.seoScripts.length > 0 ? postdata.data.seoScripts.map((value) => { return value }) :

    //     defaultscript
    //     :
    //     defaultscript
    //     :
    const navigate = useNavigate();

    localStorage.setItem('categoryname', userId.name);

    // const location = useLocation();
    // console.log(props.category) 
    return (<Layout>
        <Helmet>
            <script type="application/ld+json" >
                {JSON.stringify(scripts)}
            </script>
            {/* <title>
                {postdata !== true ? postdata.data ? postdata.data.seo ? postdata?.data?.seo?.name : '' : '' : ''}
            </title> */}
            <meta charSet="utf-8" />
            <meta name="description" content={
                postdata?.data?.seo?.description} />
            <meta name="keywords" content={
                postdata?.data?.seo?.keywords} />
            <link rel="url" href={postdata?.data?.seo?.url} />

            <meta property="og:title" content={postdata?.data?.seo?.name} />
            {/* <meta property="og:image" content="path/to/image.jpg" /> */}

            {/* <title>Sarkariprep:{userId.name}</title> */}
            {/* homedata.data.scripts.map((value, index) =>value)  */}
        </Helmet>

        {/* {console.log(postdata.data.seo, 'data')} */}
        {/* <MetaTags>
                <meta name="description" content="Government is a sarkari Naukri portal india sarkari prep provides sarkari result, government job, sarkari exam preparation for freshers." />
                <meta name="keywords" content="Government  Yojana 2022, Government Yojana, सरकारी योजना, सरकारी योजना हिंदी, Central Government Schemes, State Government Sarkari Yojana, Sarkari Yojana List 2022, Sarkari Yojana List Pdf, All India Sarkari Yojana, Government Schemes Hindi, Sarkari Yojana List of All States, Latest Government Schemes Hindi, Sarkariyojana Portal, सरकारी योजना रजिस्ट्रेशन, Sarkari Yojana Online Form, Sarkariprep" />
                <meta name="keywords" content="Government,  Government naukri result, Government Naukri, Government result, sarkari exam preparation, government jobs, sarkari jobs portal, rojgar result, sarkari naukri, online test series, sarkari pariksha, govt exams preparation, sarkari exam, sarkari job find, rojgar samachar portal, employment news, govt jobs, sarkari exam result, rojgaralert, sarkari bharti, sarkari prep, job alert, govt job alert, sarkari job, sarkari job alert, sarkariprep job portal, today news headlines, hindi news headlines, job news, education news, sarkari job news headlines, rojgar samachar news, employment news, sarkari result news, freejobalert news" />
            </MetaTags> */}


<div className='row  mx-2 justify-content-center' style={{marginTop:"40px"}}>

<div className='col-sm-11 col-md-10 col-lg-9'>
            {/* <div className='row  justify-content-center'> */}
            
            {/* <div className='col-sm-12 col-md-12 col-lg-11 postdetailsecontainer'> */}
            <div className='mb-2 '>
                <div className='mx-1 '
                >
                    <Breadcrumb style={{ 'flexWrap': 'unset' }} className='justify-content-start breadcrumb-wrapper '>
                        <Breadcrumb.Item className='fontsizeBreadcrumb' ><AiFillHome onClick={() => navigate("/")} /> </Breadcrumb.Item>
                        <Breadcrumb.Item className='fontsizeBreadcrumb  '     >
                            {userId.name.replaceAll('_', ' ')}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <Headtype1 head={userId.name.replaceAll('_', ' ')} />
                    <div className='section my-2 row   '>
                        <div className=' col-md-8  '>

                            <div className='row'>
                                {postdata.data ?

                                    postdata.data.subcategories ? postdata.data.subcategories.map((index, id) => {
                                        let ird = index.icon === undefined ? pulish : index.icon
                                        console.log();
                                        //    localStorage.setItem('subcategoryname',index.name)
                                        return (

                                            <div onClick={() => subcategoryNavigate(index._id, index.seo.slug)} key={index._id} className='col-sm-6 col-md-6  col-lg-3  curserpointer '>

                                                <Jobmegacotegorycontainer name={index ? index.name : ''} title={index ? index.name : ''} hindiname={index.seo ? index.seo.title : ''} images={ird} />
                                            </div>

                                        )
                                    }
                                    ) : console.log('')
                                    : <><JobsuiShimmer category={true} pwidth={655} /></>}





                                {

                                    postdata.data ?


                                        demopage.length > 0 && postdata?.data?.subcategories?.length > 0
                                            // ? <div className='mx-1 mt-2 mb-3'>
                                            ? <div className=' mt-2 mb-3'>

                                                <Head head={userId?.name?.replaceAll('_', ' ')} />
                                            </div> : <></> : <></>
                                }

                                {
                                    postdata.data ?


                                        postdata.data.subcategories.length > 0 || demopage.length > 0 ? <></> :
                                            <div className='col'>
                                                <>
                                                    <div style={{
                                                        'height': '100px',
                                                    }} className=' textnormal mt-5 align-items-end justify-content-md-center'>
                                                        <img src={nodata} style={{
                                                            'height': '100px',
                                                            'width': 'auto'
                                                        }} alt='No data found' />
                                                    </div>
                                                    खेद है कि हमें कोई परिणाम नहीं मिला
                                                    {/* No data found */}
                                                </>
                                            </div>

                                        : ''

                                }

                                {
                                    demopage ?

                                        demopage.map((index, id) => {
                                            const update = index.updatedAt.split("T")
                                            if (id === 0) {
                                                id = 1
                                            }
                                            const addsid = id % 4

                                            if (index.description?.qualifications && index.description.qualifications.length > 0) {
                                                console.warn(typeof index.description?.qualifications);
                                                console.warn('ss');
                                            }
                                            // index.description.qualifications && index.description.qualifications.length > 0 ? index.description.qualifications.map((value,) => { return index }) : "";

                                            return (
                                                <>

                                                    <div key={index._id} >

                                                        {/* {index.description.heading} */}
                                                        {index.type === 'Job' ?
                                                            <Jobsui slug={index?.seo?.slug} id={index._id} head={index ? index?.description ? index?.description?.short_heading : '' : ''} qualification={index.description ? index.description.qualifications : ''} date={index.description ? index.description.last_date : ''} no={index.description ? index.description.no_of_posts : ''} title={index.description ? index.description.post_name : ''} />
                                                            :
                                                            <Newsconponent slug={index?.seo?.slug} id={index._id} className='leftalignsaprate' news={index.image} title={index.heading} type='न्यूज़' Published={`Published on - ${update[0]}`} />}
                                                    </div>

                                                    {addsid === 3 ? <><Adrectengal /></> : <></>}
                                                </>
                                            )
                                        })
                                        : <>

                                            <JobsuiShimmer pwidth={655} category={true} /></>

                                }

                                {/* <Newsconponent id={'index._id'} className='leftalignsaprate' news={imgnews} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='न्यूज़' Published="Published on Oct 07, 2022" /> */}
                            </div>
                        </div>

                        <div className='d-none d-md-block d-lg-block col-md-4 col-12 col-sm-12 '>
                            <Rightbar />
                        </div>
                    </div>

                </div>
            </div>
        </div>
        </div>

    </Layout>
    );
}

export default Govjobs;

