import React, { useEffect, useState } from 'react';

import './footerpage.css';
const ButtomFooter = () => {
    const [isLoder, setLoaders] = useState(true);
    const [footerdata, setfooterdata] = useState()
    useEffect(() => {
        setLoaders(true);
        setfooterdata(JSON.parse(localStorage.getItem('footer')));
        setLoaders(false);

    }, []);
    // console.log(footerdata);
    return (
        <div>
            <footer
                className="panel-footer footer-text"
            >
                {/* {isLoder  ? ' © 2022. Sarkariprep Education Group' :
                    footerdata.map((i) => {
                        return (<div key={i.title}>

                            {i.type === 'text' ?

                                <>   &nbsp; {i.title}  &nbsp;</>
                                : <> &nbsp; {i.title}  &nbsp;</>}
                        </div>)
                    })
                }  */}
                © 2022. Sarkariprep Education Group | About | Contact | Privacy
                Policy | Disclaimer | Sitemap | DMCA
            </footer>
        </div>
    );
}

export default ButtomFooter;
