// base url

// export const BASE_URL = 'http://localhost:4000/';
export const BASE_URL    = 'https://api.sarkariprep.proapp.in/';
// version
export const API_URL = BASE_URL + 'api/v1/';
// notification
export const Notification = API_URL + 'notifications';
export const webstories_list = API_URL + 'web-stories';
export const home = API_URL + 'home';
export const posts = API_URL + 'posts';
export const post_details = API_URL + 'posts/info/';
export const post_details_next = API_URL + 'posts/info';
export const trending = API_URL + 'trendings';
export const cmsapi = API_URL + 'cms/';
export const statesapi = API_URL + 'states';
