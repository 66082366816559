import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Categorieswithgk from "./view/screen/categories/categorieswithgk";
import Govjobs from "./view/screen/govjobs/govjobs";
// import ProgressBar from "react-progressbar-on-scroll";
import Home from "./view/screen/Home/home";
import Newssereen from "./view/screen/news/newssereen";
import Postdetails from "./view/screen/postdetails/postdetails";
import Polishjobs from "./view/screen/pulishjob/polishjobs";
import Sarkarinokri from "./view/screen/sarkarinokri/sarkarinokri";
import States from "./view/screen/State/state";
import Subcategory from "./view/screen/subcategory/subcategory";
import Webstories from "./view/screen/webstories/component/webstories";
import Storiespage from "./view/screen/webstories/storiespage";
import { Suspense } from "react";
import { Shimmer } from "react-shimmer";
import CustomPage from "./view/screen/CustomPage/coustom_page";
import PostofState from "./view/screen/statepost/postofstate";




// import NotFound from "pages/NotFound";

const ProjectRoutes = (props) => {



  return (
    <>


      <Router>
        <Suspense fallback={<Shimmer height={400} width={400} />} >
          <>


            {/* <ProgressBar
          color="pink"
          gradient={true}
          colorGradient="yellow"
          height={5}
        /> */}
            {/* <div className="my mt-5 mb-4 ">  &nbsp;</div> */}

            {/* <Layout> */}
            <Routes >
              

              <Route  path="/" element={<Home />} />
              
              <Route path="/State" element={<States />} />
              <Route path="/State/:name" element={<PostofState />} />
              <Route path="/newssereen" element={<Newssereen />} />
              <Route path="/sarkarinokri" element={<Sarkarinokri />} />
              <Route exact path="/:name " element={<Govjobs />} />
              <Route exact path="/:name" element={<Postdetails />} />

              <Route path="/page/:name" element={<CustomPage />} />
              <Route path="/polishjobs/" element={<Polishjobs />} />
              <Route path="/:name/:name" element={<Subcategory />} />
              <Route path="/:name/:name/:name" element={<Postdetails />} />
              <Route path="/categorieswithgk" element={<Categorieswithgk />} />
              <Route path="/stories" element={<Storiespage />} />
              <Route path="/webstories/:id" element={<Webstories />} />
            </Routes>
            {/* </Layout> */}
            {/* <Route path="*" element={<Home />} /> */}

          </>

        </Suspense>

      </Router>

    </>
  );
};

export default ProjectRoutes;
