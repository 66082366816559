
import React from "react";

// import Head from '../../components/headtype';
import Jobsui from '../../components/jobscontainers/jobcontainer/jobsui';
// import Adsquare from '../../components/adds/Adsquare';
import Rightbar from '../../components/rightbar/rightbar';
 
import Headtype1 from "../../components/headtype1";
import { MetaTags } from 'react-meta-tags';
import Layout from "../../../layout";

function Polishjobs() {
    return (
        <Layout>
           <MetaTags>
                <title>Sarkariprep:Polish Jobs, Polish Jobs India,Polish Jobs News India</title>
                <meta name="description" content="Sarkariprep is a sarkari job portal india sarkari prep provides sarkari result, government job, sarkari exam preparation for freshers." />
                <meta name="keywords" content="Sarkari Polish Jobs, Polish Jobs India,Polish Jobs News India, Sarkariprep"/>
                <meta name="keywords" content="sarkariprep, Polish Jobs, Polish Jobs India,Polish Jobs News India, sarkari naukri result, sarkari job, sarkari result, sarkari exam preparation, government jobs, sarkari jobs portal, rojgar result, sarkari naukri, online test series, sarkari pariksha, govt exams preparation, sarkari exam, sarkari job find, rojgar samachar portal, employment news, govt jobs, sarkari exam result, rojgaralert, sarkari bharti, sarkari prep, job alert, govt job alert, sarkari job, sarkari job alert, sarkariprep job portal, today news headlines, hindi news headlines, job news, education news, sarkari job news headlines, rojgar samachar news, employment news, sarkari result news, freejobalert news" />
                <meta property="og:title" content="Sarkariprep : Sarkari News,Polish Jobs, Polish Jobs India,Polish Jobs News India, Sarkari Yojana 2022" />
                 {/* <meta property="og:image" content="path/to/image.jpg" /> */}
            </MetaTags>
           

            {/* <Headtype1 head='Govt Jobs In India' /> */}
            <div className='row  justify-content-center'>

                <div className='col-sm-12 col-md-8'>
                    <div className='container'>

                        <Headtype1 head='Polish Jobs In India' />
                        <div className='container  my-2     '>
                            <div className=" row">
                            <div className=' col-md-8 '>
                                <div className='row'>

                                    <div className='mx-1'>

                                    </div>
                                    <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                    <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                    <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                    <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                    <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                    <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                    <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                    <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                    <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                    <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />

                                </div>
                            </div>

                            <div className='col-md-4'>
                                <Rightbar />
                            </div>
                            </div>
                           
                        </div>

                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default Polishjobs