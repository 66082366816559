import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Postdetails from '../../postdetails/postdetails';
import '../Compnent/homepage_wraper.css'    
function Homepagewraper(props) {
    // const [Loaders, setLoaders] = useState(true)
    const  hometabledata =props.headdata;
    const navigate = useNavigate();

    console.log(hometabledata)
    // const [hometabledata, sethometabledata] = useState(props.headdata);
   
    // useEffect(() => {
    //     // setLoaders(true);
    //     sethometabledata(props.headdata);

    //     // setLoaders(false);
    // }, []);

    function postdetailsNavigate(id, title) {
        console.log(title.lastIndexOf("/"));
        localStorage.setItem('categoryid', `${id}`)
        localStorage.setItem('postdetailsid', `${id}`);
        // <Link to={`/${title.replaceAll(' ', '_')}`} component={<Postdetails />} />
        navigate(`/${title.replaceAll(' ', '_')}`);
    }
     
    return (

        <>
            {/* <div className=" col"></div> */}

            <div className='row textnormal'>

                {
                    hometabledata !== undefined ? Object.keys(hometabledata).map((index, id) => {
                           return (
                           <div key={index._id} className='col-sm-12 col-md-6  col-lg-4 ' >
                            <table className="table table-bordered  ">
                                <thead>
                                    <tr>
                                        <th scope="col ">  <h1  className=' textbold2 dottable'>{index}</h1></th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {hometabledata[index].map((i, ids) => { return (<tr key={i._id} onClick={() => {
                                   localStorage.setItem('categoryname', index);
                                    }}>

                                        <td   className='d-flex flex-row    m-0' onClick={()=>i?.seo?.slug ?postdetailsNavigate(i._id,i.seo.slug):' '} >
<div>                                            <div className='green '></div></div>
                                            <h2 className=' textonhover textnormal1 twolinesdot'>

                                               {i.heading}

                                            </h2>

                                        </td>

                                    </tr>) })}
                                    




                                </tbody>
                            </table>
                        </div>)
                    }) : console.log('')
                }

              
            </div>

        </ >
    )
}

export default Homepagewraper

//  <div className='col-sm-12 col-md-6  col-lg-4 ' >
// <table className="table table-bordered  ">
//     <thead>
//         <tr>
//             <th scope="col ">सरकारी नौकरी</th>
//         </tr>
//     </thead>
//     <tbody >
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate} >
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>


//     </tbody>
// </table>
// </div>
// <div className='col-sm-12 col-md-6  col-lg-4 ' >
// <table className="table table-bordered  ">
//     <thead>
//         <tr>

//             <th scope="col ">प्रवेश पत्र </th>

//         </tr>
//     </thead>
//     <tbody >
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>


//     </tbody>
// </table>
// </div>
// <div className='col-sm-12 col-md-6  col-lg-4 ' >
// <table className="table table-bordered  ">
//     <thead>
//         <tr>

//             <th scope="col "> सरकारी रिजल्ट  </th>

//         </tr>
//     </thead>
//     <tbody >
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>


//     </tbody>
// </table>
// </div>
// <div className='col-sm-12 col-md-6  col-lg-4 ' >
// <table className="table table-bordered  ">
//     <thead>
//         <tr>

//             <th scope="col ">सरकारी नौकरी</th>

//         </tr>
//     </thead>
//     <tbody >
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate} >
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>


//     </tbody>
// </table>
// </div>
// <div className='col-sm-12 col-md-6  col-lg-4 ' >
// <table className="table table-bordered  ">
//     <thead>
//         <tr>

//             <th scope="col ">प्रवेश पत्र </th>

//         </tr>
//     </thead>
//     <tbody >
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>

          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>


//     </tbody>
// </table>
// </div>
// <div className='col-sm-12 col-md-6  col-lg-4 ' >
// <table className="table table-bordered  ">
//     <thead>
//         <tr>

//             <th scope="col "> सरकारी रिजल्ट  </th>

//         </tr>
//     </thead>
//     <tbody >
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>
//         <tr onClick={() => {
//             console.log('huhyuhy');
//         }}>
          
//             <td className='d-flex flex-row    m-0' onClick={postdetailsNavigate}>
//                 <div className='green d-flex   '></div>
//                 <h6 className=' textonhover textnormal1'>

//                     Peon Jobs 2022 | चपरासी 1673 पदों पर 8वीं 10वीं पास के लिए निकली बंपर भर्ती

//                 </h6>

//             </td>

//         </tr>


//     </tbody>
// </table>
// </div> 