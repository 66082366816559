import React from 'react'
import '../components/headtype.css'
function Head(props) {
  return (
    <div className=' '>

      <div className="d-flex flex-row     justify-content-between section">
        <hr className='lines col  ' />
        {/* <h4 className=" align-items-center text-center align-content-center col-md-auto">
          {props.head}
        </h4> */}
        &nbsp;
       
        <h1 className=" textbold1 align-items-center text-center align-content-center col-md-auto">
          {props.head}
        </h1>
        
        &nbsp;
        <hr className='lines col ' />
      </div>

    </div>
  )
}

export default Head