// import React, { useEffect, useState } from 'react';
// import '../headercss/navmanu.css'
// import '../headercss/hambergor.css'
// import { useNavigate } from "react-router-dom";
// import { Nav, Offcanvas } from 'react-bootstrap';
// // import { ReactComponent as Logo } from "./logo.svg";
// import Manu  from '../../../../assets/images/align-left.png'
// import Logo  from '../../../../images/Logo.png'


// import { BsList } from 'react-icons/bs';
// import { Home_request, NotificationExport } from '../../../../request/Post';
// // import Loader from '../../../../helper/loader';
// import { homeapidata, homesdata } from '../../../../helper/simpler_state';
// import translate from '../../../../assets/images/translatehindi.png';
// import ScreenreaderLabelExample from '../../processerbar';
// import { Shimmer } from 'react-shimmer';
// import { Linearshimmer } from '../../../../helper/shimmer/linearshimmer';
// // import { IoNotifications, IoNotificationsCircleOutline } from 'react-icons/io5';
// import { IoMdNotificationsOutline } from "react-icons/io";
// function Navmanu(props) {

//     const [show, setShow] = useState(false);
//     const [notificationdata, setnotificationdata] = useState({});
//     const [isLoder, setIsLoder] = useState(true);

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
//     const [shownotification, setShownotification] = useState(false);

//     const showhandleClose = () => setShownotification(false);
//     const showhandle = () => setShownotification(true);
//     const navigate = useNavigate();
//     // const [homedata, setHomedata] = useState()
//     const [Loaders, setLoaders] = useState(true)
//     function homeNavigate() {
//         navigate("/");
//     }

//     function stateNavigate() {
//         navigate("/State");

//     }
//     // function sarkarinokriNavigate() {
//     //     navigate("/sarkarinokri");
//     // }
//     function webstoriesNavigate() {
//         navigate("/stories");
//     }

//     function govjobsNavigate(name, id) {
//         name = name.replaceAll(' ', '_');

//         console.log(name, 'name');
//         localStorage.setItem('categoryid', id);
//         navigate(`/${name} `);
//         // window.location.reload(false);
//     }
//     function subcategoryNavigate(id, name) {
//         var url1 = localStorage.getItem('categoryname');
//         localStorage.setItem('subcategoryid', id)
//         localStorage.setItem('subcategoryname', name)
//         // posts_subcategory_request(localStorage.getItem('subcategoryid')).then((users) => subcategoriesapidata(users));
//         navigate(`/${url1}/${name.replaceAll(' ', '_')}`);
//     }
//     function postdetailsNavigate(id, title) {
//         localStorage.setItem('postdetailsid', `${id}`);
//         navigate(`/${title.replaceAll(' ', '_')}`);
//         // window.location.reload(false);
//     }
//     function coustomNavigate(id, title) {
//         localStorage.setItem('cms', `${id}`);
//         navigate(`/page/${title.replaceAll(' ', '_')}`);
//         // window.location.reload(false);
//     }
//     // function polishNavigate() {
//     //     navigate("/polishjobs");
//     // }

//     // function newsNavigate() {
//     //     navigate("/newssereen");
//     // }
//     // function categoriesNavigate() {
//     //     navigate("/categorieswithgk");
//     // }
//     const homedata = homesdata.use()
//     useEffect(() => {
//         setLoaders(true);

//         // console.log('ohhhhlkknjnh');
//         if (!homedata) {
//             Home_request().then((users) => homeapidata(users))

//             // console.log('ohhhhhh');
//             // Home_request().then((users) => homeapidata(users))
//         }

//         setLoaders(false);
//         return (() => {
//             // if (!homedata) {
//             //     Home_request().then((users) => homeapidata(users))
//             //     // console.log('ohhhhhh');
//             //     // Home_request().then((users) => homeapidata(users))
//             // }

//         })


//     }, []);
//     // localStorage.getItem('footer')? 


//     return (
//         <>

//             {/* <header className="main_header"> */}
//             {/* <header className=" "> */}

// {/* <div class="container">
//   <div class="row">
//     <nav class="col-md-8 col-sm-12 col-lg-8 m-2 horizontal-navbar">
//       <!-- Navigation bar content goes here -->
//     </nav>
//   </div>
// </div>
//   */}
//             <div className=' headconteiner whitecolor'>
//          <div className='col-md-8 col-sm-12 col-lg-10 m-2 '>
//                     <div className=' align-items-center justify-content-between row ' >
//                         <div className='col-2 changeMargin' >


// <div onClick={
//     () => {
//         // NotificationExport()
//         handleShow()
//     }
// }>
//    <img src={Manu} alt="MANU" style={{cursor:"pointer"}}/>

//     {/* <BsList size={30} /> */}
// </div>

// <Offcanvas show={show} onHide={handleClose}>
//     <Offcanvas.Header closeButton>

//         <Offcanvas.Title>

//             <img src={Logo} style={{width: "70%"}} alt="Sarkariprep Job Alerts And  "

//                 className="d-inline-block align-top  "
//             />
//         </Offcanvas.Title>
//     </Offcanvas.Header>
//     <Offcanvas.Body className='offcanvasBody'>
//         <Nav.Link className='nav-links-style border-top ' onClick={homeNavigate}><h1 className='textbold3'> होम</h1> </Nav.Link>
//         <Nav.Link className='nav-links-style border-top ' onClick={stateNavigate}><h1 className='textbold3'> राज्य</h1> </Nav.Link>
//         {homedata !== false ?
//             homedata.data.sideMenus.map((index, id) => {
//                 // console.log(index);
//                 localStorage.setItem('footer', JSON.stringify(homedata.data.footers))
//                 return (<Nav.Link className='nav-links-style border-top  ' onClick={() => {

//                     index.redirectTo === 'Category' ?
//                         govjobsNavigate(`${index.seo.slug}`, `${index._id}`)
//                         : index.redirectTo === 'Sub Category' ?
//                             subcategoryNavigate(index._id, index.seo.slug)
//                             : index.redirectTo === 'Post' ? postdetailsNavigate(index._id, index.seo.slug) : index.redirectTo === "State" ? stateNavigate() : coustomNavigate(index._id, index.seo.slug);
//                 }} key={index._id}> <h1 id='nowrap' className='textonhover textbold3 dotslong  '>    {index.name}</h1> </Nav.Link>)
//             }) : <Linearshimmer />
//         }
//         {/* <Nav.Link className='nav-links-style border-top  ' onClick={stateNavigate}>  <h1 className='textbold3'>राज्य</h1></Nav.Link>
//         <Nav.Link className='nav-links-style border-top ' onClick={sarkarinokriNavigate}> <h1 className='textbold3'>सरकारी नौकरी</h1></Nav.Link>
//         <Nav.Link className='nav-links-style border-top ' onClick={webstoriesNavigate}><h1 className='textbold3'>वेब स्टोरी</h1> </Nav.Link>
//         <Nav.Link className='nav-links-style border-top  ' onClick={polishNavigate}><h1 className='textbold3'>पुलिस जॉब</h1> </Nav.Link>
//         <Nav.Link className='nav-links-style border-top  ' onClick={newsNavigate}><h1 className='textbold3'>न्यूज़</h1></Nav.Link>
//         <Nav.Link className='nav-links-style border-top  ' onClick={newsNavigate}><h1 className='textbold3'>प्रवेश पत्र</h1></Nav.Link>
//         <Nav.Link className='nav-links-style border-top ' onClick={categoriesNavigate}><h1 className='textbold3'>सामान ज्ञान</h1></Nav.Link> */}

//     </Offcanvas.Body>
// </Offcanvas>

//                         </div>
//                         <div className='col-5 text-center ' >
//                             <img src="https://sarkariprep.in/assets/sarkariprep.png" alt="SarkariprepJobs" className="d-inline-block logo" />
//                         </div>
//                         <div className=' col-2  itemright'>
//                             <div className="  notification_wrapper">

//                                 <div className='d-flex flex-row ' >
//                                     <div className='' id="notify" style={{ 'marginRight': '10px' }} onClick=

//                                         {async () => {

//                                         }}
//                                     >
//                                         <img width={23} src={translate} alt="translator" />
//                                         {/* https://www.sarkariprep.com/wp-content/themes/sarkariprep_v1/images/logo.png
//                                         <BsTranslate size={22} /> */}
//                                         {/* <img width="30" className='lngu' src="https://www.sarkariprep.com/wp-content/themes/sarkariprep_v1/images/bell.png" alt="" /> */}
//                                     </div>
//                                     <div className=''  id="notify" onClick=

//                                         {async () => {
//                                             // console.log(localStorage.getItem('notificationdatas'));
//                                             // await localStorage.getItem('notificationdatas') != null ?
//                                             //     setnotificationdata(JSON.parse(localStorage.getItem('notificationdatas')))
//                                             //     :
//                                             setIsLoder(true);
//                                             var notificationdatas = await NotificationExport();
//                                             setnotificationdata(notificationdatas);

//                                             setIsLoder(false);
//                                             // if (localStorage.getItem('notificationdatas')) {
//                                             //     console.log('if')
//                                             // } else {

//                                             //     console.log('elese')
//                                             // }


//                                             showhandle()
//                                         }}
//                                     >

//                                         <IoMdNotificationsOutline size={20} />
//                                         {/* <IoMdNotificationsOutline/> */}
//                                         {/* <img width="30" src="https://www.sarkariprep.com/wp-content/themes/sarkariprep_v1/images/bell.png" alt="" /> */}
//                                     </div>
//                                 </div>


//                                 {/* </Button> */}
// <Offcanvas show={shownotification} onHide={showhandleClose} placement='end'>
//     <Offcanvas.Header closeButton>
//         <Offcanvas.Title>नोटिफिकेशन</Offcanvas.Title>
//     </Offcanvas.Header>

//     <Offcanvas.Body className='offcanvasBody'>



//         {notificationdata.data !== undefined ? notificationdata.data.data.map((index, id) => {

//             return (
//                 <Nav.Link className='nav-links-style border-top' key={index._id} onClick={homeNavigate}><h2 className='textbold3'>{index.heading}</h2><p>  {index.description}</p> </Nav.Link>
//             )
//         }) : <div><Shimmer /></div>}


//         {/* {notificationdata.data}
//         ?  
//         {notificationdata.data.data.map((index, id) => {
//             console.log(index);
//             return (
//                 <Nav.Link className='nav-links-style border-top'  onClick={homeNavigate}><h2 className='textbold3'>{index.heading}</h2><p>  {index.description}</p> </Nav.Link>
//             )
//         })}:<Nav.Link className='nav-links-style border-top ' onClick={homeNavigate}><h2 className='textbold3'> Indian Army TES 49 Recruitment 2022 | इंडियन आर्मी 10+2 टीईएस 49 ऑनलाइन फॉर्म</h2> </Nav.Link> */}



//         {/* <Nav.Link className='nav-links-style border-top ' onClick={homeNavigate}><h2 className='textbold3'> Indian Army TES 49 Recruitment 2022 | इंडियन आर्मी 10+2 टीईएस 49 ऑनलाइन फॉर्म</h2> </Nav.Link>
//         <Nav.Link className='nav-links-style border-top ' onClick={homeNavigate}><h2 className='textbold3'> IIT Kanpur Junior Assistant Recruitment 2022 | आईआईटी कानपुर जूनियर असिस्टेंट 119 पदों की भर्ती</h2> </Nav.Link>
//         <Nav.Link className='nav-links-style border-top ' onClick={homeNavigate}><h2 className='textbold3'> ITBP Head Constable Recruitment 2022 | आईटीबीपी हेड कांस्टेबल एजुकेशन एंड स्ट्रेस काउंसलर भर्ती</h2> </Nav.Link> */}

//     </Offcanvas.Body>
// </Offcanvas>
//                             </div>
//                         </div>
//                     </div>

//                 </div>
//             </div>

//             <hr className='hrline' />

//             <div className="navbarLinks mb-0 p">
//                 <div className="mb-0 changeWidth" style={{
//                     maxWidth: 'maxContent'
//                 }}>
//                     <ul className="centernav col-md-8 col-sm-12 col-lg-10 mb-0 " style={{
//                             display: "flex",
//                             justifyContent: "space-between",
//                             marginLeft: "auto",
//                             marginRight: "auto"
//                     }} >
//                         <li className="menu-item curserpointer    ">
//                             <a title="होम" onClick={homeNavigate} ><h1 id='nowrap' className='textonhover textbold3 mx-2'>  होम</h1></a></li>
//                         {homedata !== false ?
//                             homedata.data.headers.map((index, id) => {
//                                 localStorage.setItem('footer', JSON.stringify(homedata.data.footers))
//                                 return (<li className="menu-item curserpointer   " key={index._id}><a title="डिफेंस जॉब" onClick={() => govjobsNavigate(`${index.seo.slug}`, `${index._id}`)}><h1 id='nowrap' className='textonhover textbold3 mx-2  '>    {index.name}</h1></a></li>)
//                             }) : <Linearshimmer />
//                         }

//                         <li className="menu-item curserpointer  "><a title="सरकारी रिजल्ट" onClick={webstoriesNavigate} ><h1 id='nowrap' className='textonhover textbold3 mx-2'> वेब स्टोरी</h1></a></li>

//                     </ul>


//                     {props.isscroll ? <ScreenreaderLabelExample /> : <></>}
//                 </div>
//             </div>

//         </>
//     );
// }

// export default Navmanu;



import React, { useEffect, useState } from 'react';
import '../headercss/navmanu.css'
import '../headercss/hambergor.css'
import { useNavigate } from "react-router-dom";
import { Nav, Offcanvas } from 'react-bootstrap';
// import { ReactComponent as Logo } from "./logo.svg";
import Manu from '../../../../assets/images/align-left.png'
import Logo from '../../../../images/Logo.png'
import Notify from '../../../../images/translate.png'

import { BsList } from 'react-icons/bs';
import { Home_request, NotificationExport } from '../../../../request/Post';
// import Loader from '../../../../helper/loader';
import { homeapidata, homesdata } from '../../../../helper/simpler_state';
import translate from '../../../../assets/images/translatehindi.png';
import ScreenreaderLabelExample from '../../processerbar';
import { Shimmer } from 'react-shimmer';
import { Linearshimmer } from '../../../../helper/shimmer/linearshimmer';
// import { IoNotifications, IoNotificationsCircleOutline } from 'react-icons/io5';
import { IoMdNotificationsOutline } from "react-icons/io";
function Navmanu(props) {

    const [show, setShow] = useState(false);
    const [notificationdata, setnotificationdata] = useState({});
    const [isLoder, setIsLoder] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [shownotification, setShownotification] = useState(false);

    const showhandleClose = () => setShownotification(false);
    const showhandle = () => setShownotification(true);
    const navigate = useNavigate();
    // const [homedata, setHomedata] = useState()
    const [Loaders, setLoaders] = useState(true)
    function homeNavigate() {
        navigate("/");
    }

    function stateNavigate() {
        navigate("/State");

    }
    // function sarkarinokriNavigate() {
    //     navigate("/sarkarinokri");
    // }
    function webstoriesNavigate() {
        navigate("/stories");
    }

    function govjobsNavigate(name, id) {
        name = name.replaceAll(' ', '_');

        console.log(name, 'name');
        localStorage.setItem('categoryid', id);
        navigate(`/${name} `);
        // window.location.reload(false);
    }
    function subcategoryNavigate(id, name) {
        var url1 = localStorage.getItem('categoryname');
        localStorage.setItem('subcategoryid', id)
        localStorage.setItem('subcategoryname', name)
        // posts_subcategory_request(localStorage.getItem('subcategoryid')).then((users) => subcategoriesapidata(users));
        navigate(`/${url1}/${name.replaceAll(' ', '_')}`);
    }
    function postdetailsNavigate(id, title) {
        localStorage.setItem('postdetailsid', `${id}`);
        navigate(`/${title.replaceAll(' ', '_')}`);
        // window.location.reload(false);
    }
    function coustomNavigate(id, title) {
        localStorage.setItem('cms', `${id}`);
        navigate(`/page/${title.replaceAll(' ', '_')}`);
        // window.location.reload(false);
    }

 
    // function polishNavigate() {
    //     navigate("/polishjobs");
    // }

    // function newsNavigate() {
    //     navigate("/newssereen");
    // }
    // function categoriesNavigate() {
    //     navigate("/categorieswithgk");
    // }
    const homedata = homesdata.use()
    useEffect(() => {
        setLoaders(true);

        // console.log('ohhhhlkknjnh');
        if (!homedata) {
            Home_request().then((users) => homeapidata(users))

            // console.log('ohhhhhh');
            // Home_request().then((users) => homeapidata(users))
        }

        setLoaders(false);
        return (() => {
            // if (!homedata) {
            //     Home_request().then((users) => homeapidata(users))
            //     // console.log('ohhhhhh');
            //     // Home_request().then((users) => homeapidata(users))
            // }

        })


    }, []);
    // localStorage.getItem('footer')? 


    return (
        <>

       
            <div className='row justify-content-center scroll-parent' style={{marginLeft:"1px",marginRight:"1px"}}>
             
            <div className='col-sm-11 col-md-10 col-lg-9' >


<table className="table table-bordered  " style={{    "margin-top": "10px"}}>
    <thead>
        <tr style={{borderWidth:"0px"}}>
            <th scope="col" style={{ textAlign: "left",borderWidth:"0px" }}>
                <div onClick={
                    () => {
                        // NotificationExport()
                        handleShow()
                    }
                }>
                    <img src={Manu} alt="MANU" style={{ cursor: "pointer" }} />

                    {/* <BsList size={30} /> */}
                </div>

                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>

                        <Offcanvas.Title>

                            <img src={Logo} style={{ width: "70%" }} alt="Sarkariprep Job Alerts And  "

                                className="d-inline-block align-top  "
                            />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='offcanvasBody'>
                        <Nav.Link className='nav-links-style border-top ' onClick={homeNavigate}><h1 className='textbold3'> होम</h1> </Nav.Link>
                        <Nav.Link className='nav-links-style border-top ' onClick={stateNavigate}><h1 className='textbold3'> राज्य</h1> </Nav.Link>
                        {homedata !== false ?
                            homedata.data.sideMenus.map((index, id) => {
                                // console.log(index);
                                localStorage.setItem('footer', JSON.stringify(homedata.data.footers))
                                return (<Nav.Link className='nav-links-style border-top  ' onClick={() => {

                                    index.redirectTo === 'Category' ?
                                        govjobsNavigate(`${index.seo.slug}`, `${index._id}`)
                                        : index.redirectTo === 'Sub Category' ?
                                            subcategoryNavigate(index._id, index.seo.slug)
                                            : index.redirectTo === 'Post' ? postdetailsNavigate(index._id, index.seo.slug) : index.redirectTo === "State" ? stateNavigate() : coustomNavigate(index._id, index.seo.slug);
                                }} key={index._id}> <h1 id='nowrap' className='textonhover textbold3 dotslong  '>    {index.name}</h1> </Nav.Link>)
                            }) : <Linearshimmer />
                        }

                    </Offcanvas.Body>
                </Offcanvas>


            </th>
            
            <th scope="col " colSpan={5} style={{ textAlign: "center",borderWidth:"0px" }}>
                <img src="https://sarkariprep.in/assets/sarkariprep.png" alt="SarkariprepJobs" className="d-inline-block logo" />

            </th>
            <th scope="col " style={{ textAlign: "end",borderWidth:"0px" }}>
                <div className='' id="notify" onClick=

                    {async () => {

                        setIsLoder(true);
                        var notificationdatas = await NotificationExport();
                        setnotificationdata(notificationdatas);

                        setIsLoder(false);

                        showhandle()
                    }}
                >

                    {/* <IoMdNotificationsOutline size={20} /> */}
                    <img src={Notify} alt="notification"/>


                </div>
                <Offcanvas show={shownotification} onHide={showhandleClose} placement='end'>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>नोटिफिकेशन</Offcanvas.Title>
                    </Offcanvas.Header>

                    <Offcanvas.Body className='offcanvasBody'>



                        {notificationdata.data !== undefined ? notificationdata.data.data.map((index, id) => {

                            return (
                                <Nav.Link className='nav-links-style border-top' key={index._id} onClick={homeNavigate}><h2 className='textbold3'>{index.heading}</h2><p>  {index.description}</p> </Nav.Link>
                            )
                        }) : <div><Shimmer /></div>}


                    </Offcanvas.Body>
                </Offcanvas>

            </th>

        </tr>
    </thead>
    </table>
    
</div>
<div style={{width:"100%",borderTop:"1px solid"}}></div>

<div className='col-sm-11 col-md-10 col-lg-9 scroll-child ' style={{margin:"0px",padding:"3px"}}>

    <table className="table table-bordered changesizeofnav " >
    <thead >
        <tr style={{borderWidth:"0px"}}>

    
            <th scope="col " style={{ textAlign: "left",borderWidth:"0px" }}>  
            <ul style={{padding:"0px"}}><li className="menu-item curserpointer"><a title="होम" onClick={homeNavigate}><h1 id='nowrap' className='textonhover textbold3 mx-2  '>होम</h1></a></li></ul>
           </th>

                  {homedata !== false ?
        homedata.data.headers.map((index, id) => {
            localStorage.setItem('footer', JSON.stringify(homedata.data.footers))
            return (
                <th scope="col" style={{ textAlign: "center",borderWidth:"0px" }} key={index._id}><ul style={{padding:"0px"}}><li className="menu-item curserpointer" key={index._id}><a title="डिफेंस जॉब" onClick={() => govjobsNavigate(`${index.seo.slug}`, `${index._id}`)}><h1 id='nowrap' className='textonhover textbold3 mx-2  '>    {index.name}</h1></a></li></ul></th>)
         
        }) : <Linearshimmer />
    }
           
            <th scope="col " style={{ textAlign: "end",borderWidth:"0px" }}> <ul style={{padding:"0px"}}><li className="menu-item curserpointer"><a  title="सरकारी रिजल्ट" onClick={webstoriesNavigate}><h1 id='nowrap' className='textonhover textbold3 mx-2  '>वेब स्टोरी</h1></a></li></ul></th>

        </tr>


    </thead>
</table>
</div>
            </div>



        </>
    );
}

export default Navmanu;

