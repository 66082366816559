import React from 'react';
import Header from '../../components/header/header';
import Headtype1 from '../../components/headtype1';
import pulish from '../../../assets/images/pulish.png';
import pulish1 from '../../../assets/images/police1.png';
import bank from '../../../assets/images/bank.png';
import train from '../../../assets/images/train.png';
import psc from '../../../assets/images/psc.png';
import newss from '../../../assets/images/news.png'
import teaching from '../../../assets/images/teaching.png';
import Jobmegacotegorycontainer from '../../components/jobscontainers/jobmegacategory/jobmegacotegorycontainer';
import Head from '../../components/headtype';
// import Jobsui from '../../components/jobscontainers/jobcontainer/jobsui';
// import Adsquare from '../../components/adds/Adsquare';
import { MetaTags } from 'react-meta-tags';
import Rightbar from '../../components/rightbar/rightbar';
import Newsconponent from '../../components/newscontainer/newsconponent';
import Layout from '../../../layout';
const Categorieswithgk = () => {
    return (<Layout>

        <MetaTags>
            <title>Sarkariprep:Samanya Gyan,Government knowledge</title>
            <meta name="description" content="Samanya Gyan ,gk,government knowledge" />
            <meta name="keywords" content="gk,government knowledge" />
            <meta name="keywords" content="Samanya Gyan,gk,government knowledge " />
            <meta property="og:title" content="Sarkariprep :government knowledge,government knowledge" />
            {/* <meta property="og:image" content="path/to/image.jpg" /> */}
        </MetaTags>
       
        <div className='row  justify-content-center'>

            <div className=' col-md-8 col-sm-12'>
                <div className='container'>
                    <Headtype1 head='GK In Hindi' />
                    <div className='section my-2 row   '>
                        <div className=' col-md-8 col-sm-12'>

                            <div className='row'>
                                <div className=' col-sm-6 col-6 col-md-4  col-lg-3  curserpointer  ' onClick={() => { }}>

                                    <Jobmegacotegorycontainer name='Police Jobs' hindiname='पुलिस जॉब ' images={pulish} />
                                </div>
                                <div className='col-sm-6 col-6 col-md-4  col-lg-3  curserpointer'>

                                    <Jobmegacotegorycontainer name='Railway Jobs' hindiname='रेलवे जॉब  ' images={train} />
                                </div>
                                <div className='col-sm-6 col-6 col-md-4  col-lg-3  curserpointer'>

                                    <Jobmegacotegorycontainer name='Bank Jobs' hindiname='बैंक जॉब' images={bank} />
                                </div>
                                <div className='col-sm-6 col-6 col-md-4  col-lg-3  curserpointer'>

                                    <Jobmegacotegorycontainer name='Defence Jobs' hindiname='डिफेंस जॉब' images={pulish1} />
                                </div>
                                <div className='col-sm-6 col-6 col-md-4  col-lg-3  curserpointer'>

                                    <Jobmegacotegorycontainer name='Teaching Jobs' hindiname='टीचिंग जॉब' images={teaching} />
                                </div>
                                <div className='col-sm-6 col-6 col-md-4  col-lg-3  curserpointer'>

                                    <Jobmegacotegorycontainer name='UPSC Jobs' hindiname='यूपीएससी जॉब' images={psc} />
                                </div>
                                <div className='col-sm-6 col-6 col-md-4  col-lg-3  curserpointer'>

                                    <Jobmegacotegorycontainer name='PSC Jobs' hindiname='पीएससी जॉब' images={psc} />
                                </div>
                                <div className='col-sm-6 col-6 col-md-4  col-lg-3  curserpointer'>

                                    <Jobmegacotegorycontainer name='SSC Jobs' hindiname='एसएससी जॉब' images={pulish1} />
                                </div>


                                <div className='mx-1'>

                                    <Head head='Latest Samanya Gyan' />
                                </div>
                                <div>
                                    <Newsconponent news={newss} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='इतिहास' Published="Published on Oct 07, 2022" />
                                    <Newsconponent news={newss} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='इतिहास' Published="Published on Oct 07, 2022" />
                                    <Newsconponent news={newss} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='इतिहास' Published="Published on Oct 07, 2022" />
                                    <Newsconponent news={newss} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='इतिहास' Published="Published on Oct 07, 2022" />
                                    <Newsconponent news={newss} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='इतिहास' Published="Published on Oct 07, 2022" />
                                    <Newsconponent news={newss} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='इतिहास' Published="Published on Oct 07, 2022" />
                                    <Newsconponent news={newss} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='इतिहास' Published="Published on Oct 07, 2022" />
                                    <Newsconponent news={newss} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='इतिहास' Published="Published on Oct 07, 2022" />
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <Rightbar />
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </Layout>
    );
}

export default Categorieswithgk;
