import React from 'react'
import '../components/headtype.css'
function Headtype1(props) {
  return (
    <div> 
     
      <div className=' ' >

      <div className="d-flex flex-row     justify-content-between section  ">

        <hr className='lines1 ' />
        &nbsp;
         <h1 className=" textbold1  align-self-center col-md-auto">
          {props.head}
        </h1>
        &nbsp;
     
        <hr className='lines col  ' />
      </div>

    </div></div>
  )
}

export default Headtype1