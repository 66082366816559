import React from 'react'
import ButtomFooter from './view/components/Footer/buttom_footer'
// import Header from './view/components/header/header'
import Navmanu from './view/components/header/header/navmanu'

// import Navmanu from './view/components/header/header/navmanu_1'


const Layout = ({ children }) => {
    return (
        <div>
            <div className="fixed-top   ">
                <Navmanu isscroll={children.length > 4} />
            </div>
            <div className="   mt-5 mb-4">&nbsp;</div>
            <div className='' style={{
                'position': 'relative',
                'minHeight': '100vh',
                marginTop:"50px"
            }}>{children}</div>
            <div >
                <ButtomFooter />
            </div>
        </div>
    )
}
export default Layout