import React from 'react';
import '../adds/add.css';
import AdSense from 'react-adsense';
// import { useEffect } from 'react';
const Adsquare = () => {
    // useEffect(() => {
    //     // window.adsbygoogle = window.adsbygoogle || [];
    //     // window.adsbygoogle.push({});
    // }, [])
    return (
        <div className='my-2'  >

            <h6 className='Adsquare'>Advertisement</h6>
            {/* <AdSense.Google
                client='ca-pub-4120616146317954'
                slot='1596650951'
                style={{ display: 'block' }}
                format='auto'
                responsive='true' className="adsbygoogle"
                layoutKey='-gw-1+2a-9x+5c'
            /> */}

            {/* // ads with no set-up */}

            {/* <AdSense.Google
                client='ca-pub-7292810486004926'
                slot='7806394673'
            />


            <AdSense.Google
                client='ca-pub-7292810486004926'
                slot='7806394673'
                style={{ display: 'block' }}
                layout='in-article'
                format='fluid'
            />
            <div className='ad'>
                <ins className='adsbygoogle'
                    style={{ display: 'block' }}
                    data-ad-client='div-gpt-ad-1536172937182-0'
                    data-ad-slot='/164808479/Leaderboard'
                    data-ad-format='auto' />
            </div> */}

            {/* <div className="Ads" align="center">
                <ins className="adsbygoogle"
                    style={{ 'display': 'block' }}
                    dataAdClient="ca-pub-4120616146317954"
                    dataAdSlot="1596650951"
                    dataAdFormat="auto"
                    dataFullWidthResponsive="true"></ins>
            </div> */}


        </div>
    );
}

export default Adsquare;

