import React from 'react'; 
import Headtype1 from '../../components/headtype1';
import Newsconponent from '../../components/newscontainer/newsconponent';
import Rightbar from '../../components/rightbar/rightbar';
import imgnews from '../../../assets/images/news.png';
import { MetaTags } from 'react-meta-tags';
import Layout from '../../../layout';
const Newssereen = () => {
    return (
        <Layout>
           
            <MetaTags>
                <title>Sarkariprep: Sarkari Job News, Sarkari News India</title>
                <meta name="description" content="Sarkariprep is a sarkari job portal india sarkari prep provides sarkari result, government job, sarkari exam preparation for freshers." />
                <meta name="keywords" content="Sarkari Yojana 2022, Sarkari Yojana, सरकारी योजना, सरकारी योजना हिंदी, Central Government Schemes, State Government Sarkari Yojana, Sarkari Yojana List 2022, Sarkari Yojana List Pdf, All India Sarkari Yojana, Government Schemes Hindi, Sarkari Yojana List of All States, Latest Government Schemes Hindi, Sarkariyojana Portal, सरकारी योजना रजिस्ट्रेशन, Sarkari Yojana Online Form, Sarkariprep"/>
                <meta name="keywords" content="sarkariprep, sarkariprep.in, sarkari naukri result, sarkari job, sarkari result, sarkari exam preparation, government jobs, sarkari jobs portal, rojgar result, sarkari naukri, online test series, sarkari pariksha, govt exams preparation, sarkari exam, sarkari job find, rojgar samachar portal, employment news, govt jobs, sarkari exam result, rojgaralert, sarkari bharti, sarkari prep, job alert, govt job alert, sarkari job, sarkari job alert, sarkariprep job portal, today news headlines, hindi news headlines, job news, education news, sarkari job news headlines, rojgar samachar news, employment news, sarkari result news, freejobalert news" />
                <meta property="og:title" content="Sarkariprep : Sarkari News, Sarkari Yojana 2022" />
                 {/* <meta property="og:image" content="path/to/image.jpg" /> */}
            </MetaTags>
            <div className='row  justify-content-center'>

                <div className='col-sm-12 col-md-8'>
                    <div className='container'>
                        <Headtype1 head=' Sarkari Job News' />
                        <div className='section my-2 row   '>
                            <div className='col-md-8 my-2'>
                                <Newsconponent news={imgnews} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='न्यूज़' Published="Published on Oct 07, 2022" />     
                                <Newsconponent news={imgnews} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='न्यूज़' Published="Published on Oct 07, 2022" />     
                                <Newsconponent news={imgnews} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='न्यूज़' Published="Published on Oct 07, 2022" />     
                                <Newsconponent news={imgnews} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='न्यूज़' Published="Published on Oct 07, 2022" />     
                                <Newsconponent news={imgnews} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='न्यूज़' Published="Published on Oct 07, 2022" />     
                                <Newsconponent news={imgnews} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='न्यूज़' Published="Published on Oct 07, 2022" />     
                                <Newsconponent news={imgnews} title='Up Police Constable Sports Quota Bharti 2022 | यूपी पुलिस कांस्टेबल 534 पदों पर निकली भर्ती' type='न्यूज़' Published="Published on Oct 07, 2022" />     
                                              </div>
                            <div className='col-md-4'>
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    );
}

export default Newssereen;
