import axios from 'axios';
// import { Toast } from 'react-bootstrap';
// import AutohideExample from '../helper/toaster';

import { cmsapi, home, Notification, posts, post_details, post_details_next, statesapi, trending, webstories_list } from '../url-constants';

export const NotificationExport = async () => {
    var res = await axios
        .get(Notification)


    return res.data;
}
export const Webstories = async () => {
    var res = await axios
        .get(webstories_list)


    return res.data;
}
export const Webstorydatas = async (id) => {
    var res = await axios
        .get(`${webstories_list}/${id}`)
    return res.data;
}
export const Home_request = async () => {
    var res = await axios
        .get(home)

    return res.data;
}
export const posts_request = async (id, page) => {
  
    var res = await axios
        .get(`${posts}?category=${id}&page=${page}`)

    return res.data;
}
export const posts_subcategory_request = async (id, page) => {
    var res = await axios
        .get(`${posts}?sub-category=${id}&page=${page}`)

    return res.data;
}
export const posts_state_request = async (id, page) => {
    var res = await axios
        .get(`${posts}?state=${id}&page=${page}`)

    return res.data;
}
export const posts_details_scrollnext = async (categoryid, subCategoryid, skipids) => {
    var res = await axios
        .get(`${post_details_next}?category=${categoryid}&subCategory=${subCategoryid}&skip=${skipids}`)
    return res.data;
}
export const posts_details_request = async (id) => {
    var res = await axios
        .get(`${post_details}${id}`)
    return res.data;
}
export const posts_details_scroll = async (body) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        data: { body },
    }
    var res = await axios
        .get(`${post_details_next}`, config)

    return res.data;
}
export const trendingpost = async () => {
    var res = await axios
        .get(`${trending}`)
    return res.data;
}
export const cms = async (id) => {
    var res = await axios
        .get(`${cmsapi}${id}`)
    return res.data;
}
export const states = async () => {
    var res = await axios
        .get(`${statesapi}`)
    return res.data;
}
