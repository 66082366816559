import React, { useEffect, useState } from 'react'
import Head from '../../components/headtype'
import Statecontainer from './component/statecontainer'
import logo from '../../../assets/images/logos.png';
import { MetaTags } from 'react-meta-tags';
import Layout from '../../../layout';
import { defaultscript, goToTop } from '../../../helper/simpler_state';
import { states } from '../../../request/Post';
import { Shimmer } from 'react-shimmer';
import { Helmet } from 'react-helmet';

// import { useNavigate } from 'react-router-dom';

  
function States() {
    // const navigate = useNavigate();
    // const [isLoder, setLoaders] = useState(true);
    const [postddetailsdata, setpostdetailsdata] = useState(false);
    useEffect(() => {
        goToTop();
        // setLoaders(true);

        states().then((users) => {
            setpostdetailsdata(users)
            console.log(users);
        });
        // setLoaders(false);
    }, []);

    var scripts = postddetailsdata !== false ?
        postddetailsdata?.data?.seo
            ?

            postddetailsdata?.data?.seo?.scripts
                ? Object.keys(postddetailsdata?.data?.seo?.scripts).map((value, id) => { return (id !== undefined ? postddetailsdata?.data?.seo?.scripts[value] : defaultscript) })
                :
                defaultscript
            :
            defaultscript
        :
        defaultscript
    return (
        <Layout>
            <Helmet>
                <script type="application/ld+json" >
                    {JSON.stringify(scripts)}
                </script>
                <title>
                    {postddetailsdata !== false ? postddetailsdata.data ? postddetailsdata.data.seo ? postddetailsdata?.data?.seo?.name : '' : '' : ''}</title>
                <meta charSet="utf-8" />
                <meta name="description" content={postddetailsdata?.data?.seo?.description} />
                <meta name="keywords" content={postddetailsdata?.data?.seo?.keywords} />
                <link rel="url" href={postddetailsdata?.data?.seo?.url} />

                <meta property="og:title" content={postddetailsdata?.data?.seo?.name} />

            </Helmet>
            <MetaTags>
                {/* <title>Sarkariprep:State Jobs 2022, State Wise Yojana 2022 ,State Yojana India</title> */}
                <meta name="description" content="State Wise Nokri 2022, State Wise Nokri, सरकारी योजना, सरकारी योजना हिंदी, Central Government Schemes, State Government State Wise Nokri, State Wise Nokri List 2022, State Wise Nokri List Pdf, All India State Wise Yojana, Government Schemes Hindi, State Wise Yojana List of All States, Latest Government Schemes Hindi, State Wiseyojana Portal, सरकारी योजना रजिस्ट्रेशन, State Wise Yojana Online Form, State Wiseprep" />
                <meta name="keywords" content="State Wise Nokri  , Nokri Jobs India,  Nokri News India, State Wiseprep Nokri" />
                <meta name="keywords" content="State Wiseprep, Nokri Jobs, Nokri Jobs India,Nokri Jobs News India, State Wise naukri result, State Wise job, State Wise result, State Wise exam preparation, government jobs, State Wise jobs portal, rojgar result, State Wise naukri, online test series, State Wise pariksha, govt exams preparation, State Wise exam, State Wise job find, rojgar samachar portal, employment news, govt jobs, State Wise exam result, rojgaralert, State Wise bharti, State Wise prep, job alert, govt job alert, State Wise job, State Wise job alert, State Wiseprep job portal, today news headlines, hindi news headlines, job news, education news, State Wise job news headlines, rojgar samachar news, employment news, State Wise result news, freejobalert news" />
                <meta property="og:title" content="State Wiseprep : State Wise News,Nokri Jobs, Nokri Jobs India,Nokri Jobs News India, State Wise Nokri Yojana  2022" />
                {/* <meta property="og:image" content="path/to/image.jpg" /> */}
            </MetaTags>
            {
                postddetailsdata === false
                    ?
                    <>
                        <Shimmer width={700} height={800} />
                    </> : 
                  
<div className=' mb-2 '>
<div className='mx-1 '
>
                  

                        <div className='row  justify-content-center'>

                            <div className='col-sm-11 col-md-10 col-lg-9'>
                                {/* State Wise Jobs */}
                                <Head head='राज्य' />

                                <div className='section'>
                                    <div className=' my-2'>
                                        <div className='row'>
                                            {
                                                postddetailsdata?.data?.data.map((index, id) => {
                                                    console.log(postddetailsdata);
                                                    return (
                                                        <div className=' col-6 col-sm-6 col-md-4 col-lg-3  curserpointer' onClick={() => { }} >
                                                            <Statecontainer id={index._id} name={index.name} hindiname={index.name} images={index?.icon ? index?.icon : logo} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
            }

        </Layout>
    )
}

export default States