import React from 'react'
import { Shimmer } from 'react-shimmer'

export default function Tablesrhimmer(props) {
    return (
        <div>  
             <div className='borderradious  border p-0' >
            <div className='text-center m-1    '  >
                <Shimmer height={50} width={props.pwidth} />
                {/* <h1 className='textbold2 textcolorprimary lineheight'  > {props.head}</h1> */}
            </div>
            <div className='borderradioustop'>
                <Shimmer height={30} width={props.pwidth} className="m-1" />
            </div>
            <div className='borderradioustop'>
                <Shimmer height={30} width={props.pwidth} className="m-1" />
            </div>
            <div className='borderradioustop'>
                <Shimmer height={30} width={props.pwidth} className="m-1" />
            </div>
            <div className='borderradioustop'>
                <Shimmer height={30} width={props.pwidth} className="m-1" />
            </div>
            <div className='borderradioustop'>
                <Shimmer height={30} width={props.pwidth} className="m-1" />
            </div>
            <div className='borderradioustop'>
                <Shimmer height={30} width={props.pwidth} className="m-1" />
            </div>
            <div className='borderradioustop'>
                <Shimmer height={30} width={props.pwidth} className="m-1" />
            </div>
            <div className='borderradioustop'>
                <Shimmer height={30} width={props.pwidth} className="m-1" />
            </div>
            

        </div></div>
    )
}
