import React from 'react'
import { useNavigate } from 'react-router-dom';
import Headtype1 from '../../components/headtype1';
import Jobsui from '../../components/jobscontainers/jobcontainer/jobsui';
import Rightbar from '../../components/rightbar/rightbar';
import { MetaTags } from 'react-meta-tags';
import Layout from '../../../layout';



function Sarkarinokri() {
    const navigate = useNavigate();

    function postdetailsNavigate() {
        navigate("/postdetails");
    }
    return (
        <Layout><MetaTags>
            <title>Sarkariprep:Sarkari Nokri 2022, Sarkari Yojana 2022 ,सरकारी योजना India</title>
            <meta name="description" content="Sarkari Nokri 2022, Sarkari Nokri, सरकारी योजना, सरकारी योजना हिंदी, Central Government Schemes, State Government Sarkari Nokri, Sarkari Nokri List 2022, Sarkari Nokri List Pdf, All India Sarkari Yojana, Government Schemes Hindi, Sarkari Yojana List of All States, Latest Government Schemes Hindi, Sarkariyojana Portal, सरकारी योजना रजिस्ट्रेशन, Sarkari Yojana Online Form, Sarkariprep" />
            <meta name="keywords" content="Sarkari Nokri  , Nokri Jobs India,  Nokri News India, Sarkariprep Nokri" />
            <meta name="keywords" content="sarkariprep, Nokri Jobs, Nokri Jobs India,Nokri Jobs News India, sarkari naukri result, sarkari job, sarkari result, sarkari exam preparation, government jobs, sarkari jobs portal, rojgar result, sarkari naukri, online test series, sarkari pariksha, govt exams preparation, sarkari exam, sarkari job find, rojgar samachar portal, employment news, govt jobs, sarkari exam result, rojgaralert, sarkari bharti, sarkari prep, job alert, govt job alert, sarkari job, sarkari job alert, sarkariprep job portal, today news headlines, hindi news headlines, job news, education news, sarkari job news headlines, rojgar samachar news, employment news, sarkari result news, freejobalert news" />
            <meta property="og:title" content="Sarkariprep : Sarkari News,Nokri Jobs, Nokri Jobs India,Nokri Jobs News India, Sarkari Nokri Yojana  2022" />
            {/* <meta property="og:image" content="path/to/image.jpg" /> */}
        </MetaTags>
           
            {/* <Headtype1 head='Govt Jobs In India' /> */}
            <div className='row  justify-content-center'>

                <div className='col-sm-12 col-md-8'>
                    <div className='container'>

                        <Headtype1 head='Polish Jobs In India' />
                        <div className='container my-2     '>
                            <div className="  row">
                                <div className=' col-md-8 '>
                                    <div className='row'>

                                        <div className='mx-1'>

                                        </div>
                                        <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                        <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                        <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                        <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                        <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                        <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                        <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                        <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                        <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />
                                        <Jobsui head='यूपी पुलिस स्पोर्ट्स कांस्टेबल भर्ती' qualification='10वीं / 12वीं' date='31/10/2022' no='534' title='कॉन्स्टेबल' />

                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <Rightbar />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            {/*
            <div className='row  justify-content-center'>

                <div className='col-sm-12 col-md-8'>
                    <Head head='Sarkari Nokri' />
                    {/* <Sarkarinokrihead />  
                    <div className='container mt-3'>
                        <div className='section'>
                            <div className='col' >
                                <table className="table table-bordered  ">
                                    <thead>
                                        <tr>

                                            <th scope="col ">सरकारी नौकरी रिजल्ट एवं संपूर्ण भारत सरकारी जॉब
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr onClick={
                                            postdetailsNavigate
                                        }>
                                            
                                            <td className='textnormal' > <span className="green    "> </span> इंडियन नेवी ट्रेड्समैन मेट भर्ती</td>

                                        </tr>
                                        <tr onClick={
                                            postdetailsNavigate
                                        }>
                                            
                                            <td className='textnormal' > <span className="green    "> </span> इंडियन नेवी ट्रेड्समैन मेट भर्ती</td>

                                        </tr>
                                        <tr onClick={
                                            postdetailsNavigate
                                        }>
                                            
                                            <td className='textnormal' > <span className="green    "> </span> इंडियन नेवी ट्रेड्समैन मेट भर्ती</td>

                                        </tr>
                                        <tr onClick={
                                            postdetailsNavigate
                                        }>
                                            
                                            <td className='textnormal' > <span className="green    "> </span> इंडियन नेवी ट्रेड्समैन मेट भर्ती</td>

                                        </tr>
                                        <tr onClick={
                                            postdetailsNavigate
                                        }>
                                            
                                            <td className='textnormal' > <span className="green    "> </span> इंडियन नेवी ट्रेड्समैन मेट भर्ती</td>

                                        </tr>
                                        <tr onClick={
                                            postdetailsNavigate
                                        }>
                                            
                                            <td className='textnormal' > <span className="green    "> </span> इंडियन नेवी ट्रेड्समैन मेट भर्ती</td>

                                        </tr>
                                        <tr onClick={
                                            postdetailsNavigate
                                        }>
                                            
                                            <td className='textnormal' > <span className="green    "> </span> इंडियन नेवी ट्रेड्समैन मेट भर्ती</td>

                                        </tr>
                                        <tr onClick={
                                            postdetailsNavigate
                                        }>
                                            
                                            <td className='textnormal' > <span className="green    "> </span> इंडियन नेवी ट्रेड्समैन मेट भर्ती</td>

                                        </tr>


                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>


            </div> */}

        </Layout>
    )
}

export default Sarkarinokri